<template>
  <div class="app-container">
    <div class="buttongroup">
      <el-button v-on:click="showFilter = true" type="button">筛选</el-button>
      <el-button type="primary" v-on:click="showInfo(null)" :disabled="listQuery.Role == 'supplier'">新建</el-button>
    </div>
    <!-- 新建/编辑/查看详细窗口 -->
    <el-dialog :visible.sync="showProductOrdersInfo" :close-on-click-modal="false" title="订单信息">
      <el-form :model="ProductOrdersInfo" label-width="100px">
        <el-form-item label="订单号">
          <el-input class="display" :disabled="showProductOrdersInfo_Read" v-model="ProductOrdersInfo.orderNum" />
        </el-form-item>
      </el-form>

      <el-form :inline="true" v-if="showProductOrdersInfo_Update || showProductOrdersInfo_Read" :model="ProductOrdersInfo" label-width="100px">
        <el-form-item label="创建时间">
          <el-input :disabled="true" v-model="timestampToTime111" v-if="!showProductOrdersInfo_Read" />
          <el-input class="display" :disabled="true" v-model="timestampToTime111" v-if="showProductOrdersInfo_Read" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select :disabled="true" v-model="ProductOrdersInfo.Status" v-if="!showProductOrdersInfo_Read">
            <el-option label="尚未发布" value="NotPublished"></el-option>
            <el-option label="正在进行" value="Doing"></el-option>
            <el-option label="已完成" value="Done"></el-option>
          </el-select>
          <el-input class="display" :disabled="true" v-model="InfoTransformation111" v-if="showProductOrdersInfo_Read" />
        </el-form-item>
      </el-form>

      <el-form :model="ProductOrdersInfo" label-width="100px">
        <el-form-item label="生产方">
          <el-select v-model="ProductOrdersInfo.Affiliation" placeholder="请选择生产方" :disabled="showProductOrdersInfo_Read" class="display" filterable>
            <el-option v-for="item in PlantInfo" :key="item.name" :label="item.name" :value="item.identifierStr">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="预计完成时间">
          <el-date-picker class="display" :disabled="showProductOrdersInfo_Read" placeholder="选择日期" type="date" value-format="timestamp" v-model="ProductOrdersInfo.EstimatedfinshedTime">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="客户">
          <el-input class="display" list="browsers" :disabled="showProductOrdersInfo_Read" v-model="ProductOrdersInfo.CustomerId" />
          <datalist id="browsers">
            <option v-for="item in customerInfo">
              {{ item["company"] }}
            </option>
          </datalist>
        </el-form-item>
      </el-form>

      <el-form :model="ProductOrdersInfo" label-width="100px">
        <el-form-item label="套装">
          <el-select v-model="ProductOrdersInfo.suit.suitid" placeholder="请选择" @change="SetSuit" :disabled="showProductOrdersInfo_Read">
            <el-option v-for="item in suits" :key="item._id" :label="item.orderNumber" :value="item._id">
            </el-option>
          </el-select>
          <span class="inline_input"> <el-input v-model="ProductOrdersInfo.suit.count" placeholder="请输入数量" @change="SetSuit" :disabled="showProductOrdersInfo_Read"> </el-input></span>
        </el-form-item>
        <el-form-item label="详细订单信息">
          <el-table :data="ProductOrdersInfo.Product" style="width: 100%">
            <el-table-column type="index" width="50" label="No" align="center">
            </el-table-column>
            <el-table-column label="型号" min-width="100" align="center">
              <template slot-scope="scope">
                <el-select v-model="scope.row.Type" placeholder="请选择型号" :disabled="showProductOrdersInfo_Read || ProductOrdersInfo.suit.suitid != null" class="display" filterable>
                  <el-option v-for="item in ProdutInfo" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="出库数量" min-width="100" align="center">
              <template slot-scope="scope">
                <el-input type="text" v-model="scope.row.Number" size="small" class="centerinput" :disabled="showProductOrdersInfo_Read || ProductOrdersInfo.suit.suitid != null" />
              </template>
            </el-table-column>
            <el-table-column label="已出数量" min-width="100" align="center" v-if="showProductOrdersInfo_Read">
              <template slot-scope="scope">
                <el-input type="text" v-model="scope.row.doneNumber" size="small" class="centerinput" :disabled="showProductOrdersInfo_Read" />
              </template>
            </el-table-column>
            <el-table-column label="删除此行" min-width="50" align="center" v-if="!showProductOrdersInfo_Read">
              <template slot-scope="scope">
                <el-button type="danger" size="small" circle icon="el-icon-close" @click="remove(scope.$index)" :disabled="showProductOrdersInfo_Read"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="buttonadd">
            <el-button type="primary" icon="el-icon-plus" circle :disabled="showProductOrdersInfo_Read" v-on:click="creatNew"></el-button>
          </div>
        </el-form-item>
        <el-form-item label="附件列表" v-if="this.showProductOrdersInfo_Create == false">
          <el-upload class="upload-demo" action="" multiple :http-request="submitFile" :data="ProductOrdersInfo" :on-remove="RemoveFile" :on-preview="downloadFile"
            :file-list="ProductOrdersInfo.fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">单击下载</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="附件列表" v-if="this.showProductOrdersInfo_Create == true">
          <div style="color:#ccc">新建订单后才可上传</div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer" v-if="!showProductOrdersInfo_Read">
        <el-button type="primary" v-on:click="saveInfo">保存</el-button>
        <el-button type="normal" v-on:click="ExitAndDontSave"> 取消 </el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-if="showProductOrdersInfo_Read">
        <el-button type="primary" v-on:click="MarkOrNot" icon="el-icon-star-on" v-if="ProductOrdersInfo.Attention">
          已标记
        </el-button>
        <el-button type="normal" v-on:click="MarkOrNot" icon="el-icon-star-off" v-if="!ProductOrdersInfo.Attention">
          点击标记
        </el-button>
        <el-button type="normal" v-on:click="ExitAndDontSave"> 返回 </el-button>
      </span>
    </el-dialog>

    <!-- 筛选窗口 -->
    <el-dialog :visible.sync="showFilter" :close-on-click-modal="false" class="filter" title="筛选">
      <el-form :model="FindProductOrdersInfo" label-width="100px">
        <el-form-item label="订单号">
          <el-input type="text" v-model="FindProductOrdersInfo.orderNum" class="bottom-input" size="small" />
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="FindProductOrdersInfo" label-width="100px">
        <el-form-item label="创建时间">
          <div>
            <span>
              <el-date-picker v-model="FindProductOrdersInfo.CreativeTime_dataS" type="date" placeholder="选择日期">
              </el-date-picker>
            </span>
            ~
            <span>
              <el-date-picker v-model="FindProductOrdersInfo.CreativeTime_dataE" type="date" placeholder="选择日期">
              </el-date-picker>
            </span>
          </div>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="FindProductOrdersInfo.Status" clearable>
            <el-option label="尚未发布" value="NotPublished"></el-option>
            <el-option label="正在进行" value="Doing"></el-option>
            <el-option label="已完成" value="Done"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form :model="FindProductOrdersInfo" label-width="100px">
        <el-form-item v-if="EndTimeShowJudg" label="完成时间">
          <span>
            <el-date-picker v-model="FindProductOrdersInfo.finshedTime_dataS" type="date" placeholder="选择日期">
            </el-date-picker>
          </span>
          ~
          <span>
            <el-date-picker v-model="FindProductOrdersInfo.finshedTime_dataE" type="date" placeholder="选择日期">
            </el-date-picker>
          </span>
        </el-form-item>

        <el-form-item label="生产方" v-if="this.is_supplier == false">
          <el-select v-model="FindProductOrdersInfo.Affiliation" placeholder="请选择生产方" class="display" filterable>
            <el-option v-for="item in PlantInfo" :key="item.name" :label="item.name" :value="item.identifierStr">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户">
          <el-input type="text" v-model="FindProductOrdersInfo.CustomerId" list="CustomerIdList" class="bottom-input" size="small" />
          <datalist id="CustomerIdList">
            <option v-for="item in customerInfo">
              {{ item["company"] }}
            </option>
          </datalist>
        </el-form-item>

        <el-form-item label="生产产品型号">
          <el-select v-model="FindProductOrdersInfo.Type" placeholder="请选择型号" class="display" filterable>
            <el-option v-for="item in ProdutInfo" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>

          <el-checkbox v-model="FindProductOrdersInfo.MarkSelectChecked" label="只显示标记订单" border :inline="true" style="margin-left: 20px" />
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="normal" v-on:click="closefilter">返回</el-button>
        <el-button type="normal" v-on:click="clearFilter">清空</el-button>
        <el-button type="primary" v-on:click="confirmFilter">开始筛选</el-button>
      </span>
    </el-dialog>

    <!-- 订单的显示的表格 -->
    <el-table :data="list" element-loading-text="Loading" border fit highlight-current-row>
      <el-table-column align="center" label="ID" width="95">
        <template slot-scope="scope">
          {{ scope.$index + listQuery.start + 1 }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="订单号">
        <template slot-scope="scope">
          {{ scope.row.orderNum }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="创建时间">
        <template slot-scope="scope">
          {{ InfoTransformation(scope.row.CreativeTime, 1) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="完成时间">
        <template slot-scope="scope">
          {{ InfoTransformation(scope.row.finshedTime, 1) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          {{ InfoTransformation(scope.row, 2) }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="生产方">
        <template slot-scope="scope">
          {{ getplantname(scope.row.Affiliation) }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="客户">
        <template slot-scope="scope">
          {{ scope.row.CustomerId }}
        </template>
      </el-table-column>

      <!-- <el-table-column align="center" label="详细">
        <template slot-scope="scope">
          <el-button @click="showDetails(scope.row)" type="button">查看</el-button>
        </template>
      </el-table-column> -->

      <el-table-column align="center" label="操作" width="255">
        <template slot-scope="scope">
          <div v-if="fasten(scope.row)">
            <el-button v-on:click="showInfo(scope.row)" type="button" v-if="is_supplier == false">
              编辑
            </el-button>
            <el-button v-on:click="showPublishedInfo(scope.row)" type="button" v-if="is_supplier == false">
              发布
            </el-button>
            <el-button v-on:click="showDeleteInfo(scope.row)" type="button" v-if="is_supplier == false">
              删除
            </el-button>
          </div>
          <div v-if="!fasten(scope.row)">
            <el-button v-on:click="showInfo(scope.row)" type="button" >
              订单信息
            </el-button>
            <el-button v-on:click="goNotPublic(scope.row)" type="button" v-if="scope.row.Status == 'Doing' && is_supplier == false">
              停止生产
            </el-button>
            <el-button type="button" v-on:click="showlist(scope.row)">
              产品列表
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 二次确认窗口 -->
    <el-dialog :visible.sync="showDelete" title="删除">
      <h4>确定删除</h4>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="deleteInfo">确定</el-button>
        <el-button type="normal" v-on:click="showDelete = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showPublished" title="发布">
      <h4>确定发布</h4>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="PublishInfo">确定</el-button>
        <el-button type="normal" v-on:click="showPublished = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="listvisiable" title="产品列表">
      <el-button style="margin: 0 0 20px 20px" type="primary" icon="el-icon-document" @click="handleDownload">
        Export Excel
      </el-button>
      <el-table :data="showproductlist" style="width: 100%; height: 500px; overflow: auto" v-infinite-scroll="loadmoreproduct" infinite-scroll-immediate="false" infinite-scroll-distance="1">
        <el-table-column align="center" label="ID" width="95">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="序列号">
          <template slot-scope="scope">
            {{ scope.row.SeriNum }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="出库时间">
          <template slot-scope="scope">
            {{ gettimestamps(scope.row.OutTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="类型">
          <template slot-scope="scope">
            {{ scope.row.ProcudtVersion }}
          </template>
        </el-table-column>
      </el-table>
      <div id="newoutstorage">
        <el-input v-model="outstorageNum"> </el-input>
        <el-button type="primary" plain @click="Manualdelivery" :disabled="listQuery.Role == 'supplier'">手动出库</el-button>
      </div>
    </el-dialog>
    <!-- 底部导航栏 -->
    <div class="mypagination">
      <el-pagination layout="prev, pager, next" @current-change="pagechange" :total="orderSize" :current-page="nowPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import
{
  GetStorageOrderSize,
  GetStorageOrders,
  CreateStorageOrder,
  UpdateStorageOrder,
  DeleteStorageOrder,
  GetProducts,
  Outstorage,
} from "@/api/mes";
import { getCustomerList } from "@/api/article";
import { getName } from "@/utils/auth";
import { getInfobyname } from "@/api/user";
import { GetAllPlant } from "@/api/mesproductinfo";
import { GetAllProducts } from "@/api/mesproductinfo";
import Pagination from "@/components/Pagination";
import { getsuitlist, getsuitdetail } from "@/api/suit";
import { uploadExtra, download, deleteFile } from "@/api/fileTransfer";
import { getToken } from '@/utils/auth'; // get token from cookie
export default {
  components: { Pagination },
  data()
  {
    return {
      name: "",
      is_supplier:false, 
      nowPage: 0,
      listLoading: true,
      list: [],
      Search: null,
      orderSize: 0,
      listQuery: {
        start: 0,
        limit: 10,
        filters: {},
        companycode: null,
      },
      downloadLoading: false,
      showFilter: false, //筛选窗口开关指示器
      showProductOrdersInfo: false, //新建，编辑，查看窗口开关指示器
      showProductOrdersInfo_Create: false, //新建模式指示器
      showProductOrdersInfo_Update: false, //编辑模式指示器
      showProductOrdersInfo_Read: false, //查看模式指示器
      showDelete: false, //删除确认窗口开关指示器
      showPublished: false, //发布确认窗口开关指示器

      ProductOrdersInfo: {
        _id: null,
        CreativeTime: null,
        finshedTime: null,
        EstimatedfinshedTime: null,
        CustomerId: null,
        Status: null,
        index: null,
        orderNum: null,
        Affiliation: null,
        Attention: null,
        suit: {
          suitid: null,
          count: 1,
        },
        Product: [
          {
            Number: null,
            Type: null,
            doneNumber: null,
          },
        ],
      }, //产品订单信息
      Allproducts: [],
      FindProductOrdersInfo: {
        CreativeTime_dataS: null,
        CreativeTime_dataE: null,
        finshedTime_dataS: null,
        finshedTime_dataE: null,
        CustomerId: null,
        Status: null,
        orderNum: null,
        Affiliation: null,
        Type: null,
        Attention: null,
        MarkSelectChecked: false,
      }, //筛选订单信息

      customerInfo: [{
        company: null,
        fax: null,
        add: null,
        WarrantyDays: null,
        contacts: [{
          Name: null,
          Tel: null,
          SeparateAddress: null,
        }]
      }], //客户信息

      PlantInfo: [],
      ProdutInfo: [],
      listvisiable: false,
      showproductlist: [],
      productQuery: {
        start: 0,
        limit: 10,
        filters: {},
      },
      outstorageNum: "",
      selectOrderid: null,
      suits: [],
    };
  },

  async created()
  {
    this.name = getName();
    let res = await getInfobyname(this.name);
      if (res.data.list[0].token === "supplier") {
        this.is_supplier = true;
        this.listQuery.companycode = res.data.list[0].companycode;
      };
    this.listQuery.Role = getToken();
    this.getSize();
    this.getCustomerInfo();
    this.GetPlantInfo();
    this.GetProductInfo();
    this.getallsuitlist();
    this.listLoading = false;
  },

  computed: {
    timestampToTime111: function ()
    {
      var timestamp = this.ProductOrdersInfo.CreativeTime;
      var date = new Date(timestamp); // 参数为毫秒
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    }, //新建/编辑/查看模式专用，时间转换

    InfoTransformation111: function ()
    {
      if (this.ProductOrdersInfo.Status == "NotPublished") {
        return "尚未发布";
      }
      if (this.ProductOrdersInfo.Status == "Doing") {
        return "正在进行";
      }
      if (this.ProductOrdersInfo.Status == "Done") {
        return "已完成";
      }
    }, //新建/编辑/查看模式专用，状态转换

    EndTimeShowJudg: function ()
    {
      if (this.FindProductOrdersInfo.Status == "Done") {
        return 1;
      } else {
        return 0;
      }
    }, //筛选时结束时间出现判断
  },

  methods: {
    goNotPublic(infodata)
    {
    //  console.log(infodata);
      this.$confirm('暂停该项目生产, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () =>
      {
        const { data } = await UpdateStorageOrder({
          id: infodata._id,
          data: {
            Status: "NotPublished"
          },
        });
        this.$message({
          type: 'success',
          message: '停止生产!'
        });
        this.getSize();
      }).catch(() =>
      {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    downloadFile(file)
    {
      this.$confirm(`确定下载 ${file.name}`).then(async res =>
      {
        const data = await download({
          path: file.url,
          filename: file.name
        });
        var url = window.URL.createObjectURL(data);
        var a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    async RemoveFile(param)
    {
      let name = param.name;
      let url = param.url;
      const { data } = await deleteFile({
        id: this.ProductOrdersInfo._id,
        data: url,
      });
      if (data == "success") {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        // this.ProductOrdersInfo.fileList中删除对应的file
        this.ProductOrdersInfo.fileList = this.ProductOrdersInfo.fileList.filter(
          (item) => item.name != name
        );
        UpdateStorageOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            fileList: this.ProductOrdersInfo.fileList,
          }
        });
      } else {
        this.$message({
          message: "删除失败",
          type: "warning",
        });
      }

    },
    async submitFile(param)
    {
      let obj = param.data;
      const formData = new FormData();
      formData.append("ModleName", param.file); // 要提交给后台的文件
      if (obj._id == null) {
        await this.UploadWhenCreating();
        obj._id = data;
      }
      formData.append("projectFolder", obj._id); // 这个接口必要的项目id
      const { data } = await uploadExtra(formData, {
        path: obj._id,
      });
      if (data == "success") {
        let url = "file/" + obj._id + "/" + param.file.name;
        obj.fileList.push({
          name: param.file.name,
          url: url,
        });
        UpdateStorageOrder({
          id: obj._id,
          data: {
            fileList: obj.fileList,
          },
        });
        //把图片转base64
        this.$message({
          message: "上传成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "上传失败",
          type: "warning",
        });
      }
    },
    async UploadWhenCreating()
    {
      const { data } = await CreateStorageOrder(this.ProductOrdersInfo);
     // console.log({ data });
    },
    pagechange(input)
    {
      this.listQuery.start = (Number(input) - 1) * this.listQuery.limit;
      this.fetchData();
    },
    Manualdelivery()
    {
      let sernum = this.outstorageNum;
      Outstorage({ sernum: sernum, id: this.selectOrderid })
        .then((res) =>
        {
          this.showproductlist.push(res.data);
          if (res.Status == "Done") {
            this.$message.success("出库成功，订单出库已经结束");
            this.listvisiable = false;
            this.getSize();
          } else {
            this.$message.success("出库成功");
          }
        })
        .catch((err) =>
        {
          this.$message.error(err);
          this.dialogloading = false;
        });
    },
    async loadmoreproduct()
    {
      this.productQuery.start = this.showproductlist.length;
      let resdata = await GetProducts(this.productQuery);
      resdata.list.map((item) =>
      {
        this.showproductlist.push(item);
      });
    },
    getallsuitlist()
    {
      getsuitlist().then((res) =>
      {
        this.suits = [{
          _id: null,
          orderNumber: "无",
        }];
        this.suits.push(...res.data.list);
      });
    },
    async handleDownload()
    {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) =>
      {
        const tHeader = [
          "Id",
          "类型",
          "序列号",
          "主板序列号",
          "内存序列号",
          "硬盘序列号",
          "屏幕序列号",
          "键盘1序列号",
          "键盘2序列号",
          "电源序列号(160-SK-001)",
          "出库箱号"
        ];
        const filterVal = [
          "id",
          "ProcudtVersion",
          "SeriNum",
          "MotherboardId",
          "MemorydId",
          "HardDiskId",
          "ScreenId",
          "Key1Id",
          "Key2Id",
          "PowerId",
          "OutIndex"
        ];
        const list = this.showproductlist;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "excel-" + this.ProductOrdersInfo.orderNum,
          autoWidth: true,
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData)
    {
      return jsonData.map((v, index) =>
        filterVal.map((j) =>
        {
          if (j === "id") {
            return index + 1;
          } else if (j.toString().indexOf(".") != -1) {
            let a = j.split(".");
            if (a[0] == "componentsInfomation" || a[0] == "Comment") {
              var string = "";
              var num = 13;
              for (var i = 0; i < v[a[0]].length; i++) {
                if (v[a[0]][i][a[1]] !== null) {
                  string += v[a[0]][i][a[1]];
                  string += ",";
                }
              }
              return string.substring(0, string.length - 1);
            } else {
              return v[a[0]][a[1]];
            }
          } else {
            return v[j];
          }
        })
      );
    },
    async showlist(input)
    {
      this.ProductOrdersInfo = input;
      //如果ProductOrdersINfo没有suit这个key，则加上
      if (!this.ProductOrdersInfo.hasOwnProperty("suit")) {
        this.ProductOrdersInfo.suit = {
          suitid: null,
          count: 1,
        };
      }
      let id = input._id;
      this.productQuery = {
        start: 0,
        filters: {
          StorageOrderid: id,
        },
      };
      this.selectOrderid = id;
      let resdata = await GetProducts(this.productQuery);
      this.showproductlist = resdata.list;
      this.listvisiable = true;
    },
    getplantname(input)
    {
      for (let item of this.PlantInfo) {
        if (item.identifierStr == input) {
          return item.name;
        }
      }
      return "";
    },
    async getSize()
    {
      this.listLoading = true;
      const { count } = await GetStorageOrderSize(this.listQuery);
      this.orderSize = count;
      this.fetchData();
    }, //获取数据数量

    async fetchData() {
      this.listLoading = true;
      const { count, list } = await GetStorageOrders(this.listQuery);
      this.list = list;
      setTimeout(() => {
        this.listLoading = false;
      }, 0.5 * 1000);
    }, //获取订单数据


    async getCustomerInfo()
    {
      const { data } = await getCustomerList();
      this.customerInfo = data.list;
    }, //获取客户数据
    async GetPlantInfo()
    {
      const { data } = await GetAllPlant();
      this.PlantInfo = data.list;
    }, //获取客户数据
    async GetProductInfo()
    {
      const { data } = await GetAllProducts();
      this.Allproducts = data.list;
      data.list.map((item) =>
      {
        this.ProdutInfo.push(item.name);
      });

    }, //获取客户数据

    InfoTransformation(data1, data2)
    {
      switch (data2) {
        case 1: //时间戳转换
          return this.timestampToTime(data1);
          break;
        case 2: //状态转换
        //  console.log(data1);
          if (data1.Status == "NotPublished") {
            return "尚未发布";
          }
          if (data1.Status == "Doing") {
            //data1.Product数组中的Number字段全部求和
            let totalcount = 0;
            data1.Product.map((item) =>
            {
              //如果Type中包含TT1544字段 则totalcount += item.Number;
              if (item.Type.includes("TT")) {
                totalcount += item.Number;
              }
            });
            let doneNumber = 0;
            data1.Product.map((item) =>
            {
              if (item.doneNumber != 0) {
                if (item.Type.includes("TT1544")) {
                  doneNumber += item.doneNumber;
                }
              }
            });
            return `正在进行:${doneNumber}/${totalcount}`;
          }
          if (data1.Status == "Done") {
            return "已完成";
          }
          break;
      }
    }, //数据转换


    timestampToTime(timestamp)
    {
      if (timestamp == 0 || timestamp == null || timestamp == undefined) {
        return " ";
      }
      var date = new Date(timestamp); // 参数为毫秒
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    }, //时间戳转换成时间

    showInfo(data)
    {
      this.showProductOrdersInfo = true;
      if (data == null) {
        this.ProductOrdersInfo = {
          _id: null,
          CreativeTime: null,
          finshedTime: null,
          EstimatedfinshedTime: null,
          CustomerId: null,
          Status: null,
          index: null,
          orderNum: null,
          Affiliation: null,
          Attention: null,
          suit: {
            suitid: null,
            count: 1,
          },
          Product: [
            {
              Number: null,
              Type: null,
              doneNumber: 0,
            },
          ],
        };
        this.authority(0); //新建模式的权限
      } else {
        this.ProductOrdersInfo = data;
        if (this.ProductOrdersInfo.Status == "NotPublished") {
          this.authority(1); //编辑模式的权限
        } else {
          this.authority(2); //查看模式的权限
        }
      }
      //如果ProductOrdersINfo没有suit这个key，则加上
      if (!this.ProductOrdersInfo.hasOwnProperty("suit")) {
        this.ProductOrdersInfo.suit = {
          suitid: null,
          count: 1,
        };
      }
    }, //新建，编辑，查看订单

    showPublishedInfo(data)
    {
      // let NoDoing = true;
      // for (let i = 0; i < this.list.length; i++) {
      //   if (this.list[i].Status == "Doing") {
      //     NoDoing = false;
      //   }
      // }
      // if (NoDoing == true) {
      //   this.ProductOrdersInfo._id = data._id;
      //   this.showPublished = true;
      // } else {
      //   this.$message({
      //     message:
      //       "当前存在正在进行的出库订单，无法进行发布，请先完成正在出库的订单",
      //     type: "warning",
      //   });
      // }
      this.ProductOrdersInfo = data;
      this.showPublished = true;
    }, //弹出“发布确认”窗口

    async PublishInfo()
    {
      this.ProductOrdersInfo.Status = "Doing";
      if (
        this.ProductOrdersInfo.orderNum == null ||
        this.ProductOrdersInfo.CustomerId == null ||
        this.ProductOrdersInfo.Affiliation == null
      ) {
        Message({
          message: "信息不全",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }
      const { data } = await UpdateStorageOrder({
        id: this.ProductOrdersInfo._id,
        data: {
          Status: this.ProductOrdersInfo.Status,
        },
      });
      if (data) {
        Message({
          message: "发布成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.showPublished = false;
      this.getSize();
    }, //确认发布

    authority(data)
    {
      this.showProductOrdersInfo_Create = false;
      this.showProductOrdersInfo_Update = false;
      this.showProductOrdersInfo_Read = false;
      if (data == 0) {
        this.showProductOrdersInfo_Create = true; //当前处于新建模式
      }
      if (data == 1) {
        this.showProductOrdersInfo_Update = true; //当前处于编辑模式
      }
      if (data == 2) {
        this.showProductOrdersInfo_Read = true; //当前处于查看模式
      }
    }, //新建/编辑/查看模式判断

    creatNew()
    {
      if (this.ProductOrdersInfo.Product.length < 10) {
        this.ProductOrdersInfo.Product.push({
          Number: null,
          Type: null,
          doneNumber: 0,
        });
      }
    }, //新建"详细订单"

    remove(index)
    {
      this.ProductOrdersInfo.Product.splice(index, 1);
    }, //删除"详细订单"

    fasten(data)
    {
      if (data.Status == "NotPublished") {
        return 1;
      }
      if (data.Status == "Doing" || data.Status == "Done") {
        return 0;
      }
    }, //判断订单是否发布

    Marked(data)
    {
      if (data.Attention == true) {
        return 1;
      }
      if (data.Attention == false) {
        return 0;
      }
    }, //判断订单是否标记

    async saveInfo()
    {
      if (this.ProductOrdersInfo._id == null) {
        const { data } = await CreateStorageOrder(this.ProductOrdersInfo);
      //  console.log(data);
        if (data) {
          Message({
            message: "创建成功",
            type: "success",
            duration: 5 * 1000,
          });
        }
      } else {
        const { data } = await UpdateStorageOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            finshedTime: this.ProductOrdersInfo.finshedTime,
            EstimatedfinshedTime: this.ProductOrdersInfo.EstimatedfinshedTime,
            CustomerId: this.ProductOrdersInfo.CustomerId,
            Status: this.ProductOrdersInfo.Status,
            index: this.ProductOrdersInfo.index,
            orderNum: this.ProductOrdersInfo.orderNum,
            Affiliation: this.ProductOrdersInfo.Affiliation,
            Product: this.ProductOrdersInfo.Product,
            suit: this.ProductOrdersInfo.suit,
          },
        });
      }
      this.showProductOrdersInfo = false;
      this.getSize();
    }, //新建，编辑订单的保存

    async ExitAndDontSave()
    {
      this.showProductOrdersInfo = false;
      this.getSize();
    }, //新建，编辑订单的取消（不保存+刷新数据+关闭窗口）

    async MarkOrNot()
    {
      if (this.ProductOrdersInfo.Attention == false) {
        this.ProductOrdersInfo.Attention = true;
        const { data } = await UpdateStorageOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            Attention: this.ProductOrdersInfo.Attention,
          },
        });
      } else {
        this.ProductOrdersInfo.Attention = false;
        const { data } = await UpdateStorageOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            Attention: this.ProductOrdersInfo.Attention,
          },
        });
      }
    }, //是否标记订单

    showDeleteInfo(data)
    {
      this.ProductOrdersInfo._id = data._id;
      this.showDelete = true;
    }, //弹出“订单删除”窗口

    async deleteInfo()
    {
      const { data } = await DeleteStorageOrder({
        id: this.ProductOrdersInfo._id,
      });
      if (data) {
        Message({
          message: "删除成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.showDelete = false;
      this.getSize();
    }, //确认删除

    clearFilter()
    {
      this.FindProductOrdersInfo = {
        CreativeTime_dataS: null,
        CreativeTime_dataE: null,
        finshedTime_dataS: null,
        finshedTime_dataE: null,
        CustomerId: null,
        Status: null,
        orderNum: null,
        Affiliation: null,
        Type: null,
        MarkSelectChecked: false,
      };
    }, //筛选条件清零

    closefilter()
    {
      this.showFilter = false;
      this.clearFilter();
    }, //筛选窗口关闭

    confirmFilter()
    {
      if (
        this.FindProductOrdersInfo.Affiliation != null ||
        this.FindProductOrdersInfo.CreativeTime_dataE != null ||
        this.FindProductOrdersInfo.CreativeTime_dataS != null ||
        this.FindProductOrdersInfo.CustomerId != null ||
        this.FindProductOrdersInfo.Status != null ||
        this.FindProductOrdersInfo.Type != null ||
        this.FindProductOrdersInfo.finshedTime_dataE != null ||
        this.FindProductOrdersInfo.finshedTime_dataS != null ||
        this.FindProductOrdersInfo.orderNum != null ||
        this.FindProductOrdersInfo.MarkSelectChecked != false
      ) {
        this.listQuery.filters = "({$and:[";
        if (this.FindProductOrdersInfo.orderNum) {
          this.listQuery.filters +=
            "{'orderNum':{ $regex:/^.*" +
            this.FindProductOrdersInfo.orderNum +
            ".*$/,$options: 'i'}},";
        }

        if (this.FindProductOrdersInfo.Status) {
          this.listQuery.filters +=
            "{'Status':'" + this.FindProductOrdersInfo.Status + "'},";
        }

        if (this.FindProductOrdersInfo.Affiliation) {
          this.listQuery.filters +=
            "{'Affiliation':'" + this.FindProductOrdersInfo.Affiliation + "'},";
        }

        if (this.FindProductOrdersInfo.CustomerId) {
          this.listQuery.filters +=
            "{'CustomerId':'" + this.FindProductOrdersInfo.CustomerId + "'},";
        }

        if (this.FindProductOrdersInfo.Type) {
          // labels: { $elemMatch: { name: 'vue' }
          this.listQuery.filters +=
            "{'Product':{ $elemMatch:{'Type':'" +
            this.FindProductOrdersInfo.Type +
            "'}}},";
        }
        if (this.FindProductOrdersInfo.CreativeTime_dataS) {
          this.listQuery.filters +=
            "{'CreativeTime':{$gt:'" +
            Number(this.FindProductOrdersInfo.CreativeTime_dataS) +
            "'}},";
        }
        if (this.FindProductOrdersInfo.CreativeTime_dataE) {
          this.listQuery.filters +=
            "{'CreativeTime':{$lt:'" +
            Number(this.FindProductOrdersInfo.CreativeTime_dataE) +
            "'}},";
        }
        if (this.FindProductOrdersInfo.finshedTime_dataS) {
          this.listQuery.filters +=
            "{'finshedTime':{$gt:'" +
            Number(this.FindProductOrdersInfo.finshedTime_dataS) +
            "'}},";
        }
        if (this.FindProductOrdersInfo.finshedTime_dataE) {
          this.listQuery.filters +=
            "{'finshedTime':{$lt:'" +
            Number(this.FindProductOrdersInfo.finshedTime_dataE) +
            "'}},";
        }
        if (this.FindProductOrdersInfo.MarkSelectChecked) {
          this.listQuery.filters += "{'Attention':{$lt:'" + true + "'}},";
        }
        this.listQuery.filters += "]})";
      } else {
        this.listQuery.filters = {};
      }
      this.listQuery.start = 0;
      this.nowPage = 0;
      this.getSize();
      this.showFilter = false;
    }, //开始筛选
    gettimestamps(time)
    {
      let date = new Date(time);
      var Y = date.getFullYear() + "/";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return Y + M + D + h + m;
    },
    SetSuit()
    {
      if (this.ProductOrdersInfo.suit.suitid != null) {
        this.ProductOrdersInfo.Product = [];
        this.suits.forEach(item =>
        {
          if (item._id == this.ProductOrdersInfo.suit.suitid) {
            item.products.forEach(product =>
            {
              this.Allproducts.find(info =>
              {
                if (info._id == product.productid) {
                  this.ProductOrdersInfo.Product.push({
                    Number: this.ProductOrdersInfo.suit.count,
                    Type: info.name,
                    doneNumber: 0,
                  });
                }
              });
            });
          }
        });
      }
      else {
        this.ProductOrdersInfo.Product = [];
      }
    }
  },
};
</script>



<style scoped>
span {
  display: inline-block;
}

.app-container {
  position: relative;
}

div>>>.Fuzzy_search {
  margin-left: 10px;
  margin-right: 5px;
  display: inline-block;
  width: 300px;
}

tr {
  height: 4mm;
}

td {
  height: 4mm;
  border-left: 0.5px solid #000;
  border-top: 0.5px solid #000;
}

.display>>>.el-input__inner {
  color: #606266;
  background-color: white;
}

.centerinput>>>.el-input__inner {
  color: #606266;
  background-color: white;
  text-align: center;
}

.clomun1 {
  width: 20mm;
  text-align: center;
}

.clomun2 {
  width: 150mm;
  text-align: center;
}

.delete {
  text-align: center;
}

.create {
  height: 5mm;
}

.delete:hover {
  background-image: url("../../../Img/delete.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

.create:hover {
  background-image: url("../../../Img/create.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

.mypagination {
  display: flex;
  align-content: center;
  justify-content: center;
}

.buttonadd {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.buttongroup {
  margin-bottom: 10px;
}

#newoutstorage {
  margin-top: 10px;
}

#newoutstorage>>>.el-input {
  display: inline-table;
  width: calc(100% - 120px) !important;
}

#newoutstorage>>>.el-button {
  float: right;
  width: 98px;
}

.inline_input>>>.el-input__inner {
  width: 100px;
  display: inline-block;
  margin-left: 10px;
}
</style>