import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";
const dashboard = resolve => require(["../views/dashboard/index"], resolve);
//使用了vue-routerd的[Lazy Loading Routes

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRouterMap = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },

  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true
  },

  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/index"),
        meta: { title: "主页", icon: "dashboard" }
      }
    ]
  }
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export const asyncRouterMap = [
  {
    path: "/Repair",
    component: Layout,
    redirect: "/Repair/table",
    name: "Repair",
    meta: {
      title: "维修部",
      icon: "el-icon-s-help",
      role: ["admin", "repair","supplier", "logistics"]
    },
    children: [
      {
        path: "table",
        name: "TableList",
        component: () => import("@/views/table/index"),
        meta: {
          title: "维修列表",
          icon: "table",
          role: ["admin", "repair", "logistics"]
        }
      },
      {
        path: "form",
        name: "TableInfo",
        component: () => import("@/views/form/index"),
        meta: {
          title: "维修报告",
          icon: "tree",
          role: ["admin", "repair", "logistics"]
        }
      },
      {
        path: "titleLIst",
        name: "customerInfo",
        component: () => import("@/views/titleList/index"),
        meta: {
          title: "客户信息",
          icon: "table",
          role: ["admin", "repair", "logistics"]
        }
      },
      {
        path: "componentsList",
        name: "componentsInfo",
        component: () => import("@/views/componentsList/index"),
        meta: {
          title: "物料信息",
          icon: "table",
          role: ["admin", "repair", "logistics"]
        }
      },
      {
        path: "ShippingList",
        name: "shippinglist",
        component: () => import("@/views/ShippingList/index"),
        meta: {
          title: "发货清单",
          icon: "table",
          role: ["admin", "repair", "logistics"]
        }
      },
      {
        path: "PackingSlipsAndInvoices",
        name: "PackingSlipsAndInvoices",
        component: () => import("@/views/PackingSlipsAndInvoices/index"),
        meta: {
          title: "装箱单与发票",
          icon: "table",
          role: ["admin", "repair", "logistics"]
        }
      },
      {
        path: "shippingUnit",
        name: "shippingUnit",
        component: () => import("@/views/shippingUnit/index")
      },
      {
        path: "lable",
        name: "lable",
        component: () => import("@/views/lable/index"),
        meta: {
          title: "标签列表",
          icon: "table",
          role: ["admin", "repair", "logistics"]
        }
      },
      // {
      //   path: 'faultclassification',
      //   name: 'faultclassification',
      //   component: () => import('@/views/FaultClassification/index'),
      //   meta: { title: '故障分类', icon: 'nested',role: ['admin','repair','logistics'] }
      // },
      {
        path: "summary",
        name: "summary",
        component: () => import("@/views/summary/index"),
        meta: {
          title: "故障汇总",
          icon: "nested",
          role: ["admin", "repair", "logistics"]
        }
      },
      {
        path: "table_Supplier",
        name: "TableList_Supplier",
        component: () => import("@/views/table_Supplier/index"),
        meta: {
          title: "维修列表(供应商)",
          icon: "table",
          role: ["admin","supplier","logistics","repair"]
        }
      },
      {
        path: "form_Supplier",
        name: "Form_Supplier",
        component: () => import("@/views/form_Supplier/index"),
      },

    ]
  },
  // {
  //   path: "/buglist",
  //   component: Layout,
  //   redirect: "/buglist/list",
  //   name: "Technology",
  //   meta: { title: "技术部", icon: "tech", role: ["admin", "technology"] },
  //   children: [

     
  //   ]
  // },
  {
    path: "/User",
    component: Layout,
    redirect: "/User/UserManager",
    name: "User",
    meta: {
      title: "行政部",
      icon: "Administrative",
      role: ["admin","Administrative"]
    },
    children: [
      {
        path: "UserManager",
        name: "UserManager",
        component: () => import("@/views/UserManager/index"),
        meta: {
          title: "用户管理",
          icon: "user",
          role: ["admin","Administrative"]
        }
      },
      {
        path: "WorkAttendance",
        name: "WorkAttendance",
        component: () => import("@/views/WorkAttendance/index"),
        meta: {
          title: "考勤",
          icon: "WorkAttendance",
          role: ["admin","Administrative"]
        }
      }
    ]
  },
  
  {
    path: "/product",
    component: Layout,
    redirect: "/product/ProductionManage",
    name: "Production",
    meta: {
      title: "生产部",
      icon: "production",
      role: ["admin", "Production","supplier","logistics", "repair"]
    },
    children: [
      {
        path: "ProductPreset",
        name: "ProductPreset",
        component: () => import("@/views/ProductPreset/index"),
        meta: {
          title: "产品预设",
          icon: "productinfo",
          role: ["admin", "Production","logistics", "repair"]
        }
      },
      {
        path: "ProductionPlant",
        name: "ProductionPlant",
        component: () => import("@/views/ProductionPlant/index"),
        meta: {
          title: "工厂预设",
          icon: "Plant",
          role: ["admin", "Production","logistics", "repair"]
        }
      },
        {
        path: "suit",
        name: "suitpreset",
        component: () => import("@/views/suit/index"),
        meta: {
          title: "套装预设",
          icon: "productinfo",
          role: ["admin", "Production","logistics", "repair"]
        }
      },
      {
        path: "ProductionManage",
        name: "ProductionManage",
        component: () => import("@/views/Manage/index"),
        meta: {
          title: "生产管理",
          icon: "ProductionManage",
          role: ["admin", "Production","logistics", "repair"]
        }
      },

      {
        path: "ProductData",
        name: "ProductData",
        component: () => import("@/views/ProductData/index"),
        meta: {
          title: "产品信息",
          icon: "ProductData",
          role: ["admin", "Production","supplier","logistics", "repair"]
        }
      },

      {
        path: "ProductOrderView",
        name: "ProductOrderView",
        component: () => import("@/views/ProductOrder/index"),
        meta: {
          title: "生产订单",
          icon: "OrderView",
          role: ["admin", "Production","logistics", "repair","supplier"]
        }
      },

      {
        path: "StorageOrderView",
        name: "StorageOrderView",
        component: () => import("@/views/StorageOrder/index"),
        meta: {
          title: "出库订单",
          icon: "OrderView",
          role: ["admin", "Production","logistics", "repair","supplier"]
        }
      },
      {
        path: "MaterialManagement",
        name: "MaterialManagement",
        component: () => import("@/views/MaterialManagement/index"),
        meta: {
          title: "物料库存管理",
          icon: "OrderView",
          role: ["admin", "Production","logistics", "repair","supplier"]
        }
      },
      {
        path: "MaterialList",
        name: "MaterialList",
        component: () => import("@/views/MaterialList/index"),
        meta: {
          title: "物料信息",
          icon: "OrderView",
          role: ["admin", "Production","supplier","logistics", "repair"]
        },
         
      },
            {
        path: "PcnList",
        name: "PcnList",
        component: () => import("@/views/PcnList/index"),
        meta: {
          title: "物料变更通知书",
          icon: "OrderView",
          role: ["admin", "Production","supplier","logistics", "repair"]
        }
      },]
  
  },
  
  { 
    path: "/technologyPage",
    component: Layout,
    redirect: "/technology/Project",
    name: "Technology",
    meta: {
      title: "技术部",
      icon: "Technology",
      role: ["admin", "technology", "repair"]
    },
    children: [
      {
        path: "Project",
        name: "Project",
        component: () => import("@/views/Project/index"),
        meta: {
          title: "项目管理",
          icon: "project",
          role: ["admin", "technology","repair"]
        }
      }, 
     
      {
        path: "projectReportUnit",
        name: "projectReportUnit",
        component: () => import("@/views/projectReportUnit/index")
      },
      {
        path: "ProjectTemplate",
        name: "ProjectTemplate",
        component: () => import("@/views/ProjectTemplate/index"),
        // meta: {
        //   title: "报销样式",
        //   icon: "ReimbursementFilling",
        //   role: ["admin", "technology"]
        // }
      },     
      {
        path: "list",
        name: "list",
        component: () => import("@/views/bug/index"),
        meta: { 
          title: "bug列表", 
          icon: "bug", 
          role: ["admin", "technology","repair"] 
        }
      },
      {
        path:"WxCompany",
        name:"WxCompany",
        component: () => import("@/views/WxCompany/index"),
        meta: {
          title: "客户列表(微信支持)",
          icon: "companys",
          role: ["admin", "technology","repair"]
        }
      },
      {
        path:"IssueList",
        name:"IssueList",
        component: () => import("@/views/WxIssue/index"),
        meta: {
          title: "工单列表(微信支持)",
          icon: "issuelist",
          role: ["admin", "technology","repair"]
        }
      },
      {
        path:"Wxhardward",
        name:"Wxhardward",
        component: () => import("@/views/Wxhardward/index"),
        meta: {
          title: "硬件列表(微信支持)",
          icon: "hardward",
          role: ["admin", "technology","repair"]
        }
      }
    ]
  },

//   { 
//     path: "/otherPage",
//     component: Layout,
//     redirect: "/other/index",
//     name: "Other",
//     meta: {
//       title: "其他",
//       icon: "Technology",
//       role: ["admin", "technology"]
//     },
//     children: [
//       {
//         path: "unknow",
//         name: "unknow",
//         component: () => import("@/views/other/index"),
//         meta: { 
//           title: "其他", 
//           icon: "bug", 
//           role: ["admin", "technology"] 
//         }
//       },
//     ]
//   },
  
{ path: "*", redirect: "/404", hidden: true }
];
export default router;
