<template>
  <div class="outbox">
    <div class="head">
      <el-row :gutter="10" style="display: flex; align-items: center">
        <el-col :span="21">
          <el-button @click="showSearch">筛选</el-button>
          <el-button v-on:click="showExcelUpload = true" v-if="is_supplier ==false">导入</el-button>
          <el-button v-on:click="GoshowExport" v-if="is_supplier ==false">导出</el-button>
          <el-select style="margin-left: 10px; width: 40%" multiple v-model="QuickFilterItem" @change="QuickFilter" placeholder="选择模块">
            <template slot="prefix">
              <span style="
                  padding: 5px;
                  line-height: 33px;
                  font-size: 18px;
                  color: #409eff;
                ">
              </span>
            </template>

            <el-option v-for="item in ProdutInfo" :key="item" :value="item">
              <span style="float: left">
                {{ item }}
              </span>
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="3">
          {{ "总数:" + productsize + "/未出库:" + unfinished }}
        </el-col>
      </el-row>
    </div>

    <div>
      <el-table :data="productdata" element-loading-text="Loading" border fit highlight-current-row class="outboxtable">
        <el-table-column align="center" label="ID" width="95">
          <template slot-scope="scope">
            {{ scope.$index + listQuery.start + 1 }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="序列号">
          <template slot-scope="scope">
            {{ scope.row.SeriNum }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="入库时间">
          <template slot-scope="scope">
            {{ gettimestamps(scope.row.CreationTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="类型">
          <template slot-scope="scope">
            {{ scope.row.ProcudtVersion }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="生产方">
          <template slot-scope="scope">
            {{ getplantname(scope.row.Affiliation) }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="生产订单">
          <template slot-scope="scope">
            {{ getprodcutorder(scope.row.ProductOrderid) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="出库订单">
          <template slot-scope="scope">
            {{ getprodcutorder(scope.row.StorageOrderid) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="出库箱号">
          <template slot-scope="scope">
            {{ scope.row.OutIndex ?  scope.row.OutIndex :  '-' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="watchinfo(scope.row)">查看具体信息</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="mypagination">
      <el-pagination layout="prev, pager, next" @current-change="pagechange" :total="productsize" :page-size="15" :current-page="nowPage">
      </el-pagination>
    </div>
    <el-dialog :visible.sync="showExcelUpload" title="导入">
      <el-select v-model="actIdentifierStr" size="small" class="selecticon" ref="select_icon" placeholder="">
        <el-option v-for="(item, index) in PlantInfo" :key="index" :label="item.name" :value="item.identifierStr">
        </el-option>
      </el-select>
      <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
      <el-table :data="tableData" border highlight-current-row style="overflow: scroll; height: 1000px; width: 100%; margin-top: 20px">
        <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="bulkSave">添加</el-button>
      </span>
    </el-dialog>
    <el-dialog title="具体信息" :visible.sync="infovisiable" :width="HaveImages" center>
      <el-form label-position="left" label-width="120px" :model="ShowInfo" v-if="ShowInfoImagesLength == 0">
        <el-form-item label="序列号">
          {{ ShowInfo.SeriNum }}
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="主板序列号">
          {{ ShowInfo.MotherboardId }}
          <el-button class="right" @click="changeID('MotherboardId')">修改</el-button>
        </el-form-item>
        <el-form-item label="内存序列号">
          {{ ShowInfo.MemorydId }}
          <el-button class="right" @click="changeID('MemorydId')">修改</el-button>
        </el-form-item>
        <el-form-item label="硬盘序列号">
          {{ ShowInfo.HardDiskId }}
          <el-button class="right" @click="changeID('HardDiskId')">修改</el-button>
        </el-form-item>
        <el-form-item label="液晶序列号">
          {{ ShowInfo.ScreenId }}
          <el-button class="right" @click="changeID('ScreenId')">修改</el-button>
        </el-form-item>
        <el-form-item label="触摸背板序列号">
          {{ ShowInfo.ScreenTorchId }}
          <el-button class="right" @click="changeID('ScreenTorchId')">修改</el-button>
        </el-form-item>
        <el-form-item label="触摸主控序列号">
          {{ ShowInfo.ScreenControlId }}
          <el-button class="right" @click="changeID('ScreenControlId')">修改</el-button>
        </el-form-item>
        <el-form-item label="左侧键盘序列号">
          {{ ShowInfo.Key1Id }}
          <el-button class="right" @click="changeID('Key1Id')">修改</el-button>
        </el-form-item>
        <el-form-item label="底部键盘序列号">
          {{ ShowInfo.Key2Id }}
          <el-button class="right" @click="changeID('Key2Id')">修改</el-button>
        </el-form-item>
        <el-form-item label="电源序列号">
          {{ ShowInfo.PowerId }}
          <el-button class="right" @click="changeID('PowerId')">修改</el-button>
        </el-form-item>
        <el-form-item label="硬件版本">
          {{ ShowInfo.HardWareVersion }}
          <el-button class="right" @click="changeID('HardWareVersion')">修改</el-button>
        </el-form-item>
        <el-form-item label="OS版本">
          {{ ShowInfo.OsVersion }}
          <el-button class="right" @click="changeID('OsVersion')">修改</el-button>
        </el-form-item>
      </el-form>

      <el-row :gutter="20" v-if="ShowInfoImagesLength != 0">
        <el-col :span="8">
          <el-form label-position="left" label-width="120px" :model="ShowInfo">
            <el-form-item label="序列号">
              {{ ShowInfo.SeriNum }}
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="主板序列号">
              {{ ShowInfo.MotherboardId }}
              <el-button class="right" @click="changeID('MotherboardId')">修改</el-button>
            </el-form-item>
            <el-form-item label="内存序列号">
              {{ ShowInfo.MemorydId }}
              <el-button class="right" @click="changeID('MemorydId')">修改</el-button>
            </el-form-item>
            <el-form-item label="硬盘序列号">
              {{ ShowInfo.HardDiskId }}
              <el-button class="right" @click="changeID('HardDiskId')">修改</el-button>
            </el-form-item>
            <el-form-item label="液晶序列号">
              {{ ShowInfo.ScreenId }}
              <el-button class="right" @click="changeID('ScreenId')">修改</el-button>
            </el-form-item>
            <el-form-item label="触摸背板序列号">
              {{ ShowInfo.ScreenTorchId }}
              <el-button class="right" @click="changeID('ScreenTorchId')">修改</el-button>
            </el-form-item>
            <el-form-item label="触摸主控序列号">
              {{ ShowInfo.ScreenControlId }}
              <el-button class="right" @click="changeID('ScreenControlId')">修改</el-button>
            </el-form-item>
            <el-form-item label="左侧键盘序列号">
              {{ ShowInfo.Key1Id }}
              <el-button class="right" @click="changeID('Key1Id')">修改</el-button>
            </el-form-item>
            <el-form-item label="底部键盘序列号">
              {{ ShowInfo.Key2Id }}
              <el-button class="right" @click="changeID('Key2Id')">修改</el-button>
            </el-form-item>
            <el-form-item label="电源序列号">
              {{ ShowInfo.PowerId }}
              <el-button class="right" @click="changeID('PowerId')">修改</el-button>
            </el-form-item>
            <el-form-item label="硬件版本">
              {{ ShowInfo.HardWareVersion }}
              <el-button class="right" @click="changeID('HardWareVersion')">修改</el-button>
            </el-form-item>
            <el-form-item label="OS版本">
              {{ ShowInfo.OsVersion }}
              <el-button class="right" @click="changeID('OsVersion')">修改</el-button>
            </el-form-item>
            <el-form-item label="CPU温度" v-if="ShowInfo.CpuTemp">
              {{ ShowInfo.CpuTemp / 10 }}
            </el-form-item>
            <el-form-item label="USV1温度" v-if="ShowInfo.USV1Temp">
              {{ ShowInfo.USV1Temp }}
            </el-form-item>
            <el-form-item label="USV2温度" v-if="ShowInfo.USV2Temp">
              {{ ShowInfo.USV2Temp }}
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="16" class="Image_Caed">
          <el-card style="width: 362px; margin: 10px" v-for="(item, i) in ShowInfo.images" :key="i">
            <div slot="header">
              <span>{{ ShowInfoBlob.images[i].name }}</span>
            </div>
            <el-image style="width: 320px; height: 180px" :src="ShowInfoBlob.images[i].url" fit="contain" :preview-src-list="[ShowInfoBlob.images[i].url]" @click="clickImage" />
          </el-card>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="查找" :visible.sync="serchshow" width="45%" center>
      <el-form label-position="left" label-width="140px" :model="searchData">
        <el-form-item label="订单号查找">
          <el-input v-model="searchData.orderNum" placeholder="不支持模糊搜索"></el-input>
        </el-form-item>
        <el-form-item label="序列号查找">
          <el-input v-model="searchData.SeriNum" placeholder="请输入内容"></el-input>
        </el-form-item>
        <!-- <el-form-item label="生产商查找">
          <el-select v-model="searchData.Affiliation" placeholder="请输入内容">
            <el-option v-for="item in PlantInfo" :key="item.name" :label="item.name" :value="item.identifierStr">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="型号查找">
          <el-select v-model="searchData.Type" placeholder="请输入内容">
            <el-option v-for="item in ProdutInfo" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库时间查找">
          <span>
            <el-date-picker v-model="searchData.CreativeTime_dataS" type="date" placeholder="选择日期">
            </el-date-picker>
          </span>
          ~
          <span>
            <el-date-picker v-model="searchData.CreativeTime_dataE" type="date" placeholder="选择日期">
            </el-date-picker>
          </span>
        </el-form-item>
        <el-form-item label="子组件序列号查找">
          <el-input v-model="searchData.idfuzzy" placeholder="支持模糊搜索"></el-input>
        </el-form-item>
        <el-form-item label="缺失项查找">
          <el-select v-model="searchData.MissingItems" placeholder="请输入内容">
            <el-option v-for="item in MissingItemsList" :key="item.id" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="normal" v-on:click="closefilter">返回</el-button>
        <el-button type="normal" v-on:click="clearFilter">清空</el-button>
        <el-button type="primary" v-on:click="confirmFilter">开始筛选</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showExport">
      <div class="two_row_box">
        <div class="center_box">
          <span class="bottom_5px">起始序列号</span>
          <el-input v-model="export_start_sernum" placeholder="请输入内容" @change="getexportcount"></el-input>
        </div>
        <div class="center_box">
          <span class="bottom_5px"> 结束序列号</span>
          <el-input v-model="export_end_sernum" placeholder="请输入内容" @change="getexportcount"></el-input>
        </div>
      </div>
      <div>
        <span class="bottom_5px center_box tiptext">导出数量:{{ exportcount }}</span>
      </div>
      <div class="center_box bottom_5px">
        <el-transfer v-model="exportdata" :data="exportitems"></el-transfer>
      </div>
      <div class="center_box">
        <div>
          <el-button type="primary" @click="confirmclick">确认</el-button>
          <el-button type="primary">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import
{
  GetProductSize,
  GetProducts,
  GetProductOrderNumbyId,
  GetStorageOrderNumbyId,
  bulkSave,
  GetImages,
  UpdateMesproductId
} from "@/api/mes";
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
import { GetAllPlant, UpdatePlant } from "@/api/mesproductinfo";
import { GetAllProducts } from "@/api/mesproductinfo";
import { download } from "@/api/table";
import { getMaterialList, GetCountBySerialNumber, GetMesproductBySerialNumber } from "@/api/mes";
import { getName } from "@/utils/auth";
import { MessageBox } from 'element-ui';
import { getInfobyname } from "@/api/user";
export default {
  components: {
    UploadExcelComponent,
  },
  data()
  {
    return {
      scrollContainer: HTMLCollection,
      name: "",
      is_supplier:false,
      wrapperElem: null,
      loading: true,
      productdata: [],
      hashtable: new Map(),
      listQuery: {
        start: 0,
        limit: 15,
        filters: {},
        companycode:null
      },
      nowPage: 0,
      productsize: 0,
      listLoading: false,
      tableData: [],
      unfinished: 0,
      materialUpdateList: {},
      productUpdateList: [],
      actIdentifierStr: "HaoFang",
      tableHeader: [],
      ShowInfo: {},
      ShowInfoImagesLength: 0,
      ShowInfoBlob: {},
      showExcelUpload: false,
      showExport: false,
      infovisiable: false,
      serchshow: false,
      searchData: {
        SeriNum: "",
        Affiliation: "",
        orderNum: "",
        CreativeTime_dataS: 0,
        CreativeTime_dataE: 0,
        idfuzzy: "",
        Type: "",
        MissingItems: "",
      },
      PlantInfo: [],
      ProdutInfo: [],
      trueUrl: [],
      export_start_sernum: "",
      export_end_sernum: "",
      exportdata: [],
      exportcount: 0,
      exportitems: [
        { key: "ProcudtVersion", label: "型号" },
        { key: "Affiliation", label: "生产商" },
        { key: "MotherboardId", label: "主板序列号" },
        { key: "StorageOrderid", label: "出库订单" },
        { key: "ProductOrderid", label: "生产订单" },
        {key:"OutTime",label:"出库时间"},
        { key: "OutIndex", label: "出库箱号" },
        { key: "MemorydId", label: "内存序列号" },
        { key: "HardDiskId", label: "硬盘序列号" },
        { key: "ScreenId", label: "屏幕序列号" },
        { key: "Key1Id", label: "左侧键盘序列号" },
        { key: "Key2Id", label: "底部键盘序列号" },
        { key: "PowerId", label: "电源序列号" },
        { key: "HardWareVersion", label: "硬件版本" },
        { key: "OsVersion", label: "OS版本" },
      ],
      MissingItemsList: [
        { id: "1", label: "全部" },
        { id: "2", label: "主板序列号" },
        { id: "3", label: "内存序列号" },
        { id: "4", label: "硬盘序列号" },
        { id: "5", label: "屏幕序列号" },
        { id: "6", label: "左侧键盘序列号" },
        { id: "7", label: "底部键盘序列号" },
        { id: "8", label: "电源序列号" },
        { id: "9", label: "硬件版本" },
        { id: "10", label: "OS版本" },
      ],
      QuickFilterItem: [],
    };
  },
  async created()
  {
    this.name = getName();
    let res = await getInfobyname(this.name);
      if (res.data.list[0].token === "supplier") {
        this.is_supplier = true;
        this.listQuery.companycode = res.data.list[0].companycode;
      };
    this.InitialPreset();
    this.GetPlantInfo();
    this.GetProductInfo();
  },

  computed: {
    HaveImages: function ()
    {
      if (this.ShowInfoImagesLength == 0) {
        return "30%";
      } else {
        return "65%";
      }
    },
  },

  methods: {
    GoshowExport(){
      this.showExport = true;
      this.getexportcount()
    },
    async getproductsize()
    {
      this.listLoading = true;
    //  console.log(this.name);
      // if (this.is_supplier = true) {
      //   this.listQuery.companycode = res.data.list[0].companycode;
      // };
    //  console.log( this.listQuery)
      const { count, unfinished } = await GetProductSize(this.listQuery);
      this.productsize = count;
      this.unfinished = unfinished;
      this.fetchData();
    },
    async fetchData()
    {
      this.listLoading = true;
      const { count, list } = await GetProducts(this.listQuery);
      for (let item of list) {
        if (!this.hashtable.has(item.ProductOrderid)) {
          if (item.ProductOrderid) {
            let res = await GetProductOrderNumbyId({ id: item.ProductOrderid });
            this.hashtable.set(item.ProductOrderid, res.data);
          }
        }
        if (
          item.StorageOrderid != "" &&
          item.StorageOrderid != undefined &&
          !this.hashtable.has(item.StorageOrderid)
        ) {
          let res = await GetStorageOrderNumbyId({ id: item.StorageOrderid });
          this.hashtable.set(item.StorageOrderid, res.data);
        }
      }
      this.productdata = list;
      this.listLoading = false;
    }, //获取订单数据
    pagechange(input)
    {
      this.listQuery.start = (Number(input) - 1) * this.listQuery.limit;
      this.fetchData();
    },
    getprodcutorder(input)
    {
      if (input == "") {
        return "";
      } else {
        this.$forceUpdate();
        return this.hashtable.get(input);
      }
    },

    clickImage()
    {
      this.$nextTick(() =>
      {
        let wrapper = document.getElementsByClassName(
          "el-image-viewer__actions__inner"
        );
        let downImg = document.createElement("i");
        downImg.setAttribute("class", "el-icon-download");
        wrapper[0].appendChild(downImg);
        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0];
          this.cusClickHandler();
        }
      });
    },
    cusClickHandler()
    {
      this.wrapperElem.addEventListener("click", this.hideCusBtn);
    },
    hideCusBtn(e)
    {
      let className = e.target.className;
      if (className === "el-icon-download") {
        let imgUrl = document.getElementsByClassName(
          "el-image-viewer__canvas"
        )[0].children[0].src;
        this.downloadImage(imgUrl);
      }
    },
    downloadImage(imgUrl)
    {
      let tmpArr = imgUrl.split("/");
      let fileName = tmpArr[tmpArr.length - 1];
      window.URL = window.URL || window.webkitURL;
      let xhr = new XMLHttpRequest();
      xhr.open("get", imgUrl, true);
      xhr.responseType = "blob";
      xhr.onload = function ()
      {
        if (this.status == 200) {
          //得到一个blob对象
          let blob = this.response;
          let fileUrl = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          (document.body || document.documentElement).appendChild(a);
          a.href = fileUrl;
          if ("download" in a) {
            a.download = fileName;
          } else {
            a.setAttribute("download", fileName);
          }
          a.target = "_self";
          a.click();
          a.remove();
        }
      };
      xhr.send();
    }, //参考网址：https://blog.csdn.net/weixin_45852123/article/details/119946154

    async watchinfo(info)
    {
      this.loading = true;
      this.ShowInfo = info;
     // console.log(this.ShowInfo);
      // console.log(this.ShowInfo);
      const imagess = await GetImages(this.ShowInfo);
      this.ShowInfo.images = imagess.list;

      // console.log(this.ShowInfo)

      if (this.ShowInfo.images != undefined) {
        this.ShowInfoImagesLength = this.ShowInfo.images.length;
      } else {
        this.ShowInfoImagesLength = 0;
      }
      if (this.ShowInfo.images.length > 0) {
      //  console.log(this.ShowInfo.images);
        this.ShowInfoBlob = this.deepClone(this.ShowInfo);
        this.annexPreview(this.ShowInfoBlob.images);
      }
    //  console.log(this.ShowInfo);
      this.infovisiable = true;
    },

    deepClone(obj)
    {
      let _tmp = JSON.stringify(obj); //将对象转换为json字符串形式
      let result = JSON.parse(_tmp); //将转换而来的字符串转换为原生js对象
      return result;
    }, //深拷贝

    async annexPreview(images)
    {
      for (var i = 0; i < images.length; i++) {
        this.trueUrl[i] = await download({
          path: images[i].url,
          filename: images[i].name + ".webp",
        });
        let blob = new Blob([this.trueUrl[i]], { type: "image/webp" });
        var url = window.URL.createObjectURL(blob);
        this.ShowInfoBlob.images[i].url = url;
      }
      this.loading = false;
    },
    beforeUpload(file)
    {
     // console.log();
      if (file.name.substring(file.name.lastIndexOf(".") + 1) != "xlsx") {
        this.$message({
          message: "不允许xlsx以外的文件",
          type: "warning",
        });
        return false;
      }
      const isLt1M = file.size / 1024 / 1024 < 10;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header })
    {
      this.tableData = results;
      this.tableHeader = header;
    },
    async bulkSave()
    {
      var datalist = [];
      let elTable = this.tableData;
      let elHeader = this.tableHeader;

      let productIdNr = null;
      let SeriNumNr = null;
      let NumberNr = null;
      let TypeNr = null;
      for (let j = 0; j < elHeader.length; j++) {
        if (elHeader[j].includes("编号")) {
          productIdNr = j;
        } else if (elHeader[j].includes("数量")) {
          NumberNr = j;
        } else if (elHeader[j].includes("序列号")) {
          SeriNumNr = j;
        } else if (elHeader[j].includes("描述")) {
          TypeNr = j;
        }
      }

      for (let i = 0; i < elTable.length; i++) {
        datalist.push({
          productId: elTable[i][elHeader[productIdNr]],
          Number: elTable[i][elHeader[NumberNr]],
          SeriNum: elTable[i][elHeader[SeriNumNr]],
          Type: elTable[i][elHeader[TypeNr]],
        });
      }
      const { data } = await GetAllProducts();
      let productList = data.list;
      this.productUpdateList = [];
      this.materialUpdateList = [];
      let TempMaterialUpdateList = {};
      for (let k = 0; k < datalist.length; k++) {
        if (
          productList.some((obj) =>
            obj.productId.includes(datalist[k].productId)
          )
        ) {
          this.productUpdateList.push(datalist[k]);
        } else {
          if (TempMaterialUpdateList[datalist[k].productId]) {
            TempMaterialUpdateList[datalist[k].productId] += Number(
              datalist[k].Number
            );
          } else {
            TempMaterialUpdateList[datalist[k].productId] = 0;
            TempMaterialUpdateList[datalist[k].productId] += Number(
              datalist[k].Number
            );
          }
        }
      }
      const materialList = await getMaterialList();
      for (let l = 0; l < materialList.data.list.length; l++) {
        if (TempMaterialUpdateList[materialList.data.list[l].number]) {
          this.materialUpdateList[materialList.data.list[l]._id] =
            TempMaterialUpdateList[materialList.data.list[l].number];
        }
      }

      let nonImportedProducts = []; // 创建一个新数组来存储未导入的产品

      for (let product of this.productUpdateList) {
        const fetchedProduct = await GetProducts({
          limit: 1,
          filters: {
            SeriNum: product.SeriNum,
          },
        });
        if (fetchedProduct.list.length === 0) {
          // 如果产品未被导入，则添加到新数组中
          nonImportedProducts.push(product);
        }
      }

      // 此时 nonImportedProducts 包含所有未导入的产品
      this.productUpdateList = nonImportedProducts;
      await this.GetPlantInfo();
      let elPlantInfo = this.PlantInfo;
      let elmaterialUpdateList = this.materialUpdateList;
      for (let m = 0; m < elPlantInfo.length; m++) {
        if (elPlantInfo[m].identifierStr == this.actIdentifierStr) {
          for (let n = 0; n < elPlantInfo[m].MaterialInventory.length; n++) {
            if (elmaterialUpdateList[elPlantInfo[m].MaterialInventory[n].id]) {
              if (elPlantInfo[m].MaterialInventory[n].total == null) {
                elPlantInfo[m].MaterialInventory[n].total = 0;
              }
              elPlantInfo[m].MaterialInventory[n].total +=
                elmaterialUpdateList[elPlantInfo[m].MaterialInventory[n].id];
              elPlantInfo[m].MaterialInventory[n].operateHistory.unshift({
                warehousingNum: parseInt(
                  elmaterialUpdateList[elPlantInfo[m].MaterialInventory[n].id]
                ),
                InDate: new Date().valueOf(),
                Operater: getName(),
                scrapNum: 0,
              });
            }
          }
          await UpdatePlant({
            _id: elPlantInfo[m]._id,
            MaterialInventory: elPlantInfo[m].MaterialInventory,
          })
            .then((res) =>
            {
              this.$message.success("更新成功");
            })
            .catch((err) =>
            {
              this.$message.error(err);
            });
          break;
        }
      }
      let blukSaveData = [];
      for (let o = 0; o < this.productUpdateList.length; o++) {
        blukSaveData.push({
          insertOne: {
            document: {
              CreationTime: new Date().valueOf(),
              ProcudtVersion: this.productUpdateList[o].Type,
              SeriNum: this.productUpdateList[o].SeriNum,
              Affiliation: this.actIdentifierStr,
              StorageOrderid: "",
            },
          },
        });
      }
      await bulkSave(blukSaveData)
        .then((res) =>
        {
          this.$message.success("更新成功");
        })
        .catch((err) =>
        {
          this.$message.error(err);
        });
      this.fetchData();
    },
    gettimestamps(time)
    {
      let date = new Date(time);
      var Y = date.getFullYear() + "/";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return Y + M + D + h + m;
    },
    showSearch()
    {
      this.serchshow = true;
    },
    async GetPlantInfo()
    {
      const { data } = await GetAllPlant();
      this.PlantInfo = data.list;
    },
    getplantname(input)
    {
      for (let item of this.PlantInfo) {
        if (item.identifierStr == input) {
          return item.name;
        }
      }
      return "";
    },
    async GetProductInfo()
    {
      const { data } = await GetAllProducts();
      data.list.map((item) =>
      {
        this.ProdutInfo.push(item.name);
      });
    }, //获取客户数据
    closefilter()
    {
      this.serchshow = false;
    },
    clearFilter()
    {
      this.searchData = {
        SeriNum: "",
        Affiliation: "",
        orderNum: "",
        CreativeTime_dataS: 0,
        CreativeTime_dataE: 0,
        idfuzzy: "",
        Type: "",
        MissingItems: "",
      };
    },
    findKey(table, res)
    {
      for (let [key, value] of table) {
        if (value == res) return key;
      }
    },
    confirmFilter()
    {
      if (
        this.searchData.SeriNum != "" ||
        this.searchData.Affiliation != "" ||
        this.searchData.orderNum != "" ||
        this.searchData.idfuzzy != "" ||
        this.searchData.Type != "" ||
        this.searchData.CreativeTime_dataS != 0 ||
        this.searchData.CreativeTime_dataE != 0 ||
        this.searchData.MissingItems != ""
      ) {
        this.listQuery.filters = "({$and:[";
        if (this.searchData.SeriNum) {
          this.listQuery.filters +=
            "{'SeriNum':{ $regex:/^.*" +
            this.searchData.SeriNum +
            ".*$/,$options: 'i'}},";
        }
        if (this.searchData.orderNum) {
          this.listQuery.filters +=
            "{$or: [" +
            "{'ProductOrderid':{ $regex:/^.*" +
            this.findKey(this.hashtable, this.searchData.orderNum) +
            ".*$/,$options: 'i'}}," +
            "{'StorageOrderid':{ $regex:/^.*" +
            this.findKey(this.hashtable, this.searchData.orderNum) +
            ".*$/,$options: 'i'}}" +
            "]},";
        }
        if (this.searchData.Affiliation) {
          this.listQuery.filters +=
            "{'Affiliation':'" + this.searchData.Affiliation + "'},";
        }
        if (this.searchData.Type) {
          this.listQuery.filters +=
            "{'ProcudtVersion':'" + this.searchData.Type + "'},";
        }
        if (this.searchData.CreativeTime_dataS) {
          this.listQuery.filters +=
            "{'CreationTime':{$gt:'" +
            Number(this.searchData.CreativeTime_dataS) +
            "'}},";
        }
        if (this.searchData.CreativeTime_dataE) {
          this.listQuery.filters +=
            "{'CreationTime':{$lt:'" +
            Number(this.searchData.CreativeTime_dataE) +
            "'}},";
        }
        if (this.searchData.idfuzzy) {
          this.listQuery.filters +=
            "{$or: [" +
            "{'MotherboardId':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}," +
            "{'ScreenControlId':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}," +
            "{'ScreenTorchId':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}," +
            "{'MemorydId':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}," +
            "{'HardDiskId':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}," +
            "{'ScreenId':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}," +
            "{'Key1Id':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}," +
            "{'Key2Id':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}," +
            "{'PowerId':{ $regex:/^.*" +
            this.searchData.idfuzzy +
            ".*$/,$options: 'i'}}" +
            "]},";
        }
        if (this.searchData.MissingItems) {
          if (this.searchData.MissingItems == "全部") {
            this.listQuery.filters +=
              "{$or:[" +
              "{'MotherboardId':''}," +
              "{'MemorydId':''}," +
              "{'HardDiskId':''}," +
              "{'ScreenId':''}," +
              "{'Key1Id':''}," +
              "{'Key2Id':''}," +
              "{'PowerId':''}," +
              "{'HardWareVersion':''}," +
              "{'OsVersion':''}" +
              "]},";
          }
          if (this.searchData.MissingItems == "主板序列号") {
            this.listQuery.filters += "{'MotherboardId':''},";
          }
          if (this.searchData.MissingItems == "内存序列号") {
            this.listQuery.filters += "{'MemorydId':''},";
          }
          if (this.searchData.MissingItems == "硬盘序列号") {
            this.listQuery.filters += "{'HardDiskId':''},";
          }
          if (this.searchData.MissingItems == "液晶序列号") {
            this.listQuery.filters += "{'ScreenId':''},";
          }
          if (this.searchData.MissingItems == "左侧键盘序列号") {
            this.listQuery.filters += "{'Key1Id':''},";
          }
          if (this.searchData.MissingItems == "底部键盘序列号") {
            this.listQuery.filters += "{'Key2Id':''},";
          }
          if (this.searchData.MissingItems == "电源序列号") {
            this.listQuery.filters += "{'PowerId':''},";
          }
          if (this.searchData.MissingItems == "硬件版本") {
            this.listQuery.filters += "{'HardWareVersion':''},";
          }
          if (this.searchData.MissingItems == "OS版本") {
            this.listQuery.filters += "{'OsVersion':''},";
          }
        }

        this.listQuery.filters += "]})";
      } else {
        this.listQuery.filters = {};
      }
    //  console.log(this.listQuery.filters);
      this.QuickFilterItem = [];
      this.listQuery.start = 0;
      this.nowPage = 0;
      this.getproductsize();
      this.serchshow = false;
    },
    QuickFilter()
    {
    //  console.log(this.QuickFilterItem);
      this.$cookies.set("QuickFilterItem", this.QuickFilterItem);
      // document.cookie = `QuickFilterItem = ${this.QuickFilterItem}`;

      if (this.QuickFilterItem.length > 0) {
        this.listQuery.filters = "({$or:[";
        for (let i = 0; i < this.QuickFilterItem.length; i++) {
          this.listQuery.filters +=
            "{'ProcudtVersion':'" + this.QuickFilterItem[i] + "'},";
        }
        this.listQuery.filters += "]})";
      } else {
        this.listQuery.filters = {};
      }
      // console.log(this.listQuery);
      this.listQuery.start = 0;
      this.nowPage = 0;
      this.getproductsize();
    },
    InitialPreset()
    {
      this.QuickFilterItem = [];
      this.QuickFilterItem = this.$cookies.get("QuickFilterItem");
      if (this.QuickFilterItem == null) {
        this.QuickFilterItem = [];
        this.QuickFilterItem[0] = "TT1544-Z5";
      }
      this.QuickFilter();
    },
    getexportcount()
    {
      GetCountBySerialNumber({
        startSeri: this.export_start_sernum,
        endSeri: this.export_end_sernum,
      }).then(res =>
      {
        this.exportcount = res.data.count;
      }).catch(err =>
      {
      //  console.log(err);
      });
    },
    formatJson(filterVal, jsonData)
    {
      return jsonData.map((v, index) =>
        filterVal.map((j) =>
        {
          if (j === "_id") {
            return index + 1;
          } else if (j.toString().indexOf(".") != -1) {
            let a = j.split(".");
            if (a[1] == "Comment") {
              var string = "";
              var num = 13;
              for (var i = 0; i < v[a[0]].length; i++) {
                if (v[a[0]][i][a[1]] !== null) {
                  string += v[a[0]][i][a[1]];
                  string += ",";
                }
              }
              return string.substring(0, string.length - 1);
            } else {
              return v[a[0]][a[1]];
            }
          } else {
            return v[j];
          }
        })
      );
    },
    confirmclick()
    {
      this.showExport = false;
      GetMesproductBySerialNumber({
        startSeri: this.export_start_sernum,
        endSeri: this.export_end_sernum,
      }).then(res =>
      {
        let resdata = res.data.list;
        // 修改 OutTime 格式
        for (const element of resdata) {
          if (element.OutTime) {
            let outTime = new Date(element.OutTime);
            element.OutTime = outTime.toISOString().slice(0, 19).replace('T', ' '); // 转换为 yyyy-mm-dd h:m:s 格式
          }
        } 
       // console.log(resdata)

        let tHeader = ['SeriNum'];
        let filterVal = ['SeriNum'];
        for (let item of this.exportdata) {
          tHeader.push(item);
          filterVal.push(item);
        }
        import("@/vendor/Export2Excel").then((excel) =>
        {
          const data = this.formatJson(filterVal, resdata);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: "导出数据",
            autoWidth: true,
            bookType: "xlsx",
          });
          this.$message.success("导出成功");
        });
      }).catch(err =>
      {
      //  console.log(err);
      });

    },
    changeID(key)
    {
      //弹框获取数据
      MessageBox.prompt("输入序列号").then(({ value }) =>
      {
        UpdateMesproductId({
          _id: this.ShowInfo._id,
          PersionName: getName(),
          key,
          newid: value
        }).then(res =>
        {
          this.$message.success("修改成功");
          this.ShowInfo[key] = value;
        }).catch(err =>
        {
       //   console.log(err);
        });
      });
    }
  },
};
</script>

<style>
.head {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.mypagination {
  display: flex;
  align-content: center;
  justify-content: center;
}

.Image_Caed {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.el-table .el-table__cell {
  padding: 10px 0px;
}

.two_row_box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.center_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottom_5px {
  margin-bottom: 5px;
}

.tiptext {
  font-size: 10px;
  color: gray;
}

.right {
  float: right;
}
</style>