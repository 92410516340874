var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "buttongroup" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.showFilter = true
                },
              },
            },
            [_vm._v("筛选")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.listQuery.Role == "supplier",
              },
              on: {
                click: function ($event) {
                  return _vm.showInfo(null)
                },
              },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showProductOrdersInfo,
            "close-on-click-modal": false,
            title: "订单信息",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showProductOrdersInfo = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.ProductOrdersInfo, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    staticClass: "display",
                    attrs: { disabled: _vm.showProductOrdersInfo_Read },
                    model: {
                      value: _vm.ProductOrdersInfo.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ProductOrdersInfo, "orderNum", $$v)
                      },
                      expression: "ProductOrdersInfo.orderNum",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showProductOrdersInfo_Update || _vm.showProductOrdersInfo_Read
            ? _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    model: _vm.ProductOrdersInfo,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [
                      !_vm.showProductOrdersInfo_Read
                        ? _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.timestampToTime111,
                              callback: function ($$v) {
                                _vm.timestampToTime111 = $$v
                              },
                              expression: "timestampToTime111",
                            },
                          })
                        : _vm._e(),
                      _vm.showProductOrdersInfo_Read
                        ? _c("el-input", {
                            staticClass: "display",
                            attrs: { disabled: true },
                            model: {
                              value: _vm.timestampToTime111,
                              callback: function ($$v) {
                                _vm.timestampToTime111 = $$v
                              },
                              expression: "timestampToTime111",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      !_vm.showProductOrdersInfo_Read
                        ? _c(
                            "el-select",
                            {
                              attrs: { disabled: true },
                              model: {
                                value: _vm.ProductOrdersInfo.Status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ProductOrdersInfo, "Status", $$v)
                                },
                                expression: "ProductOrdersInfo.Status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "尚未发布",
                                  value: "NotPublished",
                                },
                              }),
                              _c("el-option", {
                                attrs: { label: "正在进行", value: "Doing" },
                              }),
                              _c("el-option", {
                                attrs: { label: "已完成", value: "Done" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showProductOrdersInfo_Read
                        ? _c("el-input", {
                            staticClass: "display",
                            attrs: { disabled: true },
                            model: {
                              value: _vm.InfoTransformation111,
                              callback: function ($$v) {
                                _vm.InfoTransformation111 = $$v
                              },
                              expression: "InfoTransformation111",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form",
            { attrs: { model: _vm.ProductOrdersInfo, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "生产方" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "display",
                      attrs: {
                        placeholder: "请选择生产方",
                        disabled: _vm.showProductOrdersInfo_Read,
                        filterable: "",
                      },
                      model: {
                        value: _vm.ProductOrdersInfo.Affiliation,
                        callback: function ($$v) {
                          _vm.$set(_vm.ProductOrdersInfo, "Affiliation", $$v)
                        },
                        expression: "ProductOrdersInfo.Affiliation",
                      },
                    },
                    _vm._l(_vm.PlantInfo, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.identifierStr },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预计完成时间" } },
                [
                  _c("el-date-picker", {
                    staticClass: "display",
                    attrs: {
                      disabled: _vm.showProductOrdersInfo_Read,
                      placeholder: "选择日期",
                      type: "date",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.ProductOrdersInfo.EstimatedfinshedTime,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ProductOrdersInfo,
                          "EstimatedfinshedTime",
                          $$v
                        )
                      },
                      expression: "ProductOrdersInfo.EstimatedfinshedTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户" } },
                [
                  _c("el-input", {
                    staticClass: "display",
                    attrs: {
                      list: "browsers",
                      disabled: _vm.showProductOrdersInfo_Read,
                    },
                    model: {
                      value: _vm.ProductOrdersInfo.CustomerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ProductOrdersInfo, "CustomerId", $$v)
                      },
                      expression: "ProductOrdersInfo.CustomerId",
                    },
                  }),
                  _c(
                    "datalist",
                    { attrs: { id: "browsers" } },
                    _vm._l(_vm.customerInfo, function (item) {
                      return _c("option", [
                        _vm._v(" " + _vm._s(item["company"]) + " "),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            { attrs: { model: _vm.ProductOrdersInfo, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "套装" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        disabled: _vm.showProductOrdersInfo_Read,
                      },
                      on: { change: _vm.SetSuit },
                      model: {
                        value: _vm.ProductOrdersInfo.suit.suitid,
                        callback: function ($$v) {
                          _vm.$set(_vm.ProductOrdersInfo.suit, "suitid", $$v)
                        },
                        expression: "ProductOrdersInfo.suit.suitid",
                      },
                    },
                    _vm._l(_vm.suits, function (item) {
                      return _c("el-option", {
                        key: item._id,
                        attrs: { label: item.orderNumber, value: item._id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "inline_input" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入数量",
                          disabled: _vm.showProductOrdersInfo_Read,
                        },
                        on: { change: _vm.SetSuit },
                        model: {
                          value: _vm.ProductOrdersInfo.suit.count,
                          callback: function ($$v) {
                            _vm.$set(_vm.ProductOrdersInfo.suit, "count", $$v)
                          },
                          expression: "ProductOrdersInfo.suit.count",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细订单信息" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.ProductOrdersInfo.Product },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          width: "50",
                          label: "No",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "型号",
                          "min-width": "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "display",
                                    attrs: {
                                      placeholder: "请选择型号",
                                      disabled:
                                        _vm.showProductOrdersInfo_Read ||
                                        _vm.ProductOrdersInfo.suit.suitid !=
                                          null,
                                      filterable: "",
                                    },
                                    model: {
                                      value: scope.row.Type,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "Type", $$v)
                                      },
                                      expression: "scope.row.Type",
                                    },
                                  },
                                  _vm._l(_vm.ProdutInfo, function (item) {
                                    return _c("el-option", {
                                      key: item,
                                      attrs: { label: item, value: item },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "出库数量",
                          "min-width": "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "centerinput",
                                  attrs: {
                                    type: "text",
                                    size: "small",
                                    disabled:
                                      _vm.showProductOrdersInfo_Read ||
                                      _vm.ProductOrdersInfo.suit.suitid != null,
                                  },
                                  model: {
                                    value: scope.row.Number,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "Number", $$v)
                                    },
                                    expression: "scope.row.Number",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.showProductOrdersInfo_Read
                        ? _c("el-table-column", {
                            attrs: {
                              label: "已出数量",
                              "min-width": "100",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticClass: "centerinput",
                                        attrs: {
                                          type: "text",
                                          size: "small",
                                          disabled:
                                            _vm.showProductOrdersInfo_Read,
                                        },
                                        model: {
                                          value: scope.row.doneNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "doneNumber",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.doneNumber",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3639520740
                            ),
                          })
                        : _vm._e(),
                      !_vm.showProductOrdersInfo_Read
                        ? _c("el-table-column", {
                            attrs: {
                              label: "删除此行",
                              "min-width": "50",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-button", {
                                        attrs: {
                                          type: "danger",
                                          size: "small",
                                          circle: "",
                                          icon: "el-icon-close",
                                          disabled:
                                            _vm.showProductOrdersInfo_Read,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.remove(scope.$index)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1766427026
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "buttonadd" },
                    [
                      _c("el-button", {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          circle: "",
                          disabled: _vm.showProductOrdersInfo_Read,
                        },
                        on: { click: _vm.creatNew },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              this.showProductOrdersInfo_Create == false
                ? _c(
                    "el-form-item",
                    { attrs: { label: "附件列表" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "",
                            multiple: "",
                            "http-request": _vm.submitFile,
                            data: _vm.ProductOrdersInfo,
                            "on-remove": _vm.RemoveFile,
                            "on-preview": _vm.downloadFile,
                            "file-list": _vm.ProductOrdersInfo.fileList,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v("单击下载")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              this.showProductOrdersInfo_Create == true
                ? _c("el-form-item", { attrs: { label: "附件列表" } }, [
                    _c("div", { staticStyle: { color: "#ccc" } }, [
                      _vm._v("新建订单后才可上传"),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          !_vm.showProductOrdersInfo_Read
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.saveInfo } },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "normal" },
                      on: { click: _vm.ExitAndDontSave },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showProductOrdersInfo_Read
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.ProductOrdersInfo.Attention
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-star-on" },
                          on: { click: _vm.MarkOrNot },
                        },
                        [_vm._v(" 已标记 ")]
                      )
                    : _vm._e(),
                  !_vm.ProductOrdersInfo.Attention
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "normal", icon: "el-icon-star-off" },
                          on: { click: _vm.MarkOrNot },
                        },
                        [_vm._v(" 点击标记 ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "normal" },
                      on: { click: _vm.ExitAndDontSave },
                    },
                    [_vm._v(" 返回 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "filter",
          attrs: {
            visible: _vm.showFilter,
            "close-on-click-modal": false,
            title: "筛选",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFilter = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.FindProductOrdersInfo,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.FindProductOrdersInfo.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.FindProductOrdersInfo, "orderNum", $$v)
                      },
                      expression: "FindProductOrdersInfo.orderNum",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              attrs: {
                inline: true,
                model: _vm.FindProductOrdersInfo,
                "label-width": "100px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "创建时间" } }, [
                _c("div", [
                  _c(
                    "span",
                    [
                      _c("el-date-picker", {
                        attrs: { type: "date", placeholder: "选择日期" },
                        model: {
                          value: _vm.FindProductOrdersInfo.CreativeTime_dataS,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.FindProductOrdersInfo,
                              "CreativeTime_dataS",
                              $$v
                            )
                          },
                          expression:
                            "FindProductOrdersInfo.CreativeTime_dataS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" ~ "),
                  _c(
                    "span",
                    [
                      _c("el-date-picker", {
                        attrs: { type: "date", placeholder: "选择日期" },
                        model: {
                          value: _vm.FindProductOrdersInfo.CreativeTime_dataE,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.FindProductOrdersInfo,
                              "CreativeTime_dataE",
                              $$v
                            )
                          },
                          expression:
                            "FindProductOrdersInfo.CreativeTime_dataE",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.FindProductOrdersInfo.Status,
                        callback: function ($$v) {
                          _vm.$set(_vm.FindProductOrdersInfo, "Status", $$v)
                        },
                        expression: "FindProductOrdersInfo.Status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "尚未发布", value: "NotPublished" },
                      }),
                      _c("el-option", {
                        attrs: { label: "正在进行", value: "Doing" },
                      }),
                      _c("el-option", {
                        attrs: { label: "已完成", value: "Done" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.FindProductOrdersInfo,
                "label-width": "100px",
              },
            },
            [
              _vm.EndTimeShowJudg
                ? _c("el-form-item", { attrs: { label: "完成时间" } }, [
                    _c(
                      "span",
                      [
                        _c("el-date-picker", {
                          attrs: { type: "date", placeholder: "选择日期" },
                          model: {
                            value: _vm.FindProductOrdersInfo.finshedTime_dataS,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.FindProductOrdersInfo,
                                "finshedTime_dataS",
                                $$v
                              )
                            },
                            expression:
                              "FindProductOrdersInfo.finshedTime_dataS",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" ~ "),
                    _c(
                      "span",
                      [
                        _c("el-date-picker", {
                          attrs: { type: "date", placeholder: "选择日期" },
                          model: {
                            value: _vm.FindProductOrdersInfo.finshedTime_dataE,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.FindProductOrdersInfo,
                                "finshedTime_dataE",
                                $$v
                              )
                            },
                            expression:
                              "FindProductOrdersInfo.finshedTime_dataE",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              this.is_supplier == false
                ? _c(
                    "el-form-item",
                    { attrs: { label: "生产方" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "display",
                          attrs: {
                            placeholder: "请选择生产方",
                            filterable: "",
                          },
                          model: {
                            value: _vm.FindProductOrdersInfo.Affiliation,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.FindProductOrdersInfo,
                                "Affiliation",
                                $$v
                              )
                            },
                            expression: "FindProductOrdersInfo.Affiliation",
                          },
                        },
                        _vm._l(_vm.PlantInfo, function (item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: {
                              label: item.name,
                              value: item.identifierStr,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "客户" } },
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: {
                      type: "text",
                      list: "CustomerIdList",
                      size: "small",
                    },
                    model: {
                      value: _vm.FindProductOrdersInfo.CustomerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.FindProductOrdersInfo, "CustomerId", $$v)
                      },
                      expression: "FindProductOrdersInfo.CustomerId",
                    },
                  }),
                  _c(
                    "datalist",
                    { attrs: { id: "CustomerIdList" } },
                    _vm._l(_vm.customerInfo, function (item) {
                      return _c("option", [
                        _vm._v(" " + _vm._s(item["company"]) + " "),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生产产品型号" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "display",
                      attrs: { placeholder: "请选择型号", filterable: "" },
                      model: {
                        value: _vm.FindProductOrdersInfo.Type,
                        callback: function ($$v) {
                          _vm.$set(_vm.FindProductOrdersInfo, "Type", $$v)
                        },
                        expression: "FindProductOrdersInfo.Type",
                      },
                    },
                    _vm._l(_vm.ProdutInfo, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                  _c("el-checkbox", {
                    staticStyle: { "margin-left": "20px" },
                    attrs: {
                      label: "只显示标记订单",
                      border: "",
                      inline: true,
                    },
                    model: {
                      value: _vm.FindProductOrdersInfo.MarkSelectChecked,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.FindProductOrdersInfo,
                          "MarkSelectChecked",
                          $$v
                        )
                      },
                      expression: "FindProductOrdersInfo.MarkSelectChecked",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "normal" }, on: { click: _vm.closefilter } },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                { attrs: { type: "normal" }, on: { click: _vm.clearFilter } },
                [_vm._v("清空")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmFilter },
                },
                [_vm._v("开始筛选")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(scope.$index + _vm.listQuery.start + 1) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.orderNum) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.InfoTransformation(scope.row.CreativeTime, 1)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "完成时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.InfoTransformation(scope.row.finshedTime, 1)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.InfoTransformation(scope.row, 2)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "生产方" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getplantname(scope.row.Affiliation)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "客户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.CustomerId) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "255" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.fasten(scope.row)
                      ? _c(
                          "div",
                          [
                            _vm.is_supplier == false
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showInfo(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                )
                              : _vm._e(),
                            _vm.is_supplier == false
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showPublishedInfo(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 发布 ")]
                                )
                              : _vm._e(),
                            _vm.is_supplier == false
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDeleteInfo(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.fasten(scope.row)
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showInfo(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 订单信息 ")]
                            ),
                            scope.row.Status == "Doing" &&
                            _vm.is_supplier == false
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goNotPublic(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 停止生产 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showlist(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 产品列表 ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDelete, title: "删除" },
          on: {
            "update:visible": function ($event) {
              _vm.showDelete = $event
            },
          },
        },
        [
          _c("h4", [_vm._v("确定删除")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteInfo } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "normal" },
                  on: {
                    click: function ($event) {
                      _vm.showDelete = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showPublished, title: "发布" },
          on: {
            "update:visible": function ($event) {
              _vm.showPublished = $event
            },
          },
        },
        [
          _c("h4", [_vm._v("确定发布")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.PublishInfo } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "normal" },
                  on: {
                    click: function ($event) {
                      _vm.showPublished = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.listvisiable, title: "产品列表" },
          on: {
            "update:visible": function ($event) {
              _vm.listvisiable = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { margin: "0 0 20px 20px" },
              attrs: { type: "primary", icon: "el-icon-document" },
              on: { click: _vm.handleDownload },
            },
            [_vm._v(" Export Excel ")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadmoreproduct,
                  expression: "loadmoreproduct",
                },
              ],
              staticStyle: { width: "100%", height: "500px", overflow: "auto" },
              attrs: {
                data: _vm.showproductlist,
                "infinite-scroll-immediate": "false",
                "infinite-scroll-distance": "1",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "ID", width: "95" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "序列号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.SeriNum) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "出库时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.gettimestamps(scope.row.OutTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.ProcudtVersion) + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "newoutstorage" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.outstorageNum,
                  callback: function ($$v) {
                    _vm.outstorageNum = $$v
                  },
                  expression: "outstorageNum",
                },
              }),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    disabled: _vm.listQuery.Role == "supplier",
                  },
                  on: { click: _vm.Manualdelivery },
                },
                [_vm._v("手动出库")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mypagination" },
        [
          _c("el-pagination", {
            attrs: {
              layout: "prev, pager, next",
              total: _vm.orderSize,
              "current-page": _vm.nowPage,
            },
            on: { "current-change": _vm.pagechange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }