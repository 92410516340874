var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.PrintHidden
        ? _c(
            "div",
            {
              staticStyle: {
                border: "#000 3px solid",
                "border-radius": "10px",
                width: "300px",
                height: "300px",
                position: "fixed",
                right: "100px",
                top: "300px",
              },
            },
            [
              _c(
                "h6",
                {
                  staticStyle: {
                    padding: "5px",
                    "padding-bottom": "0px",
                    "font-size": "large",
                  },
                },
                [_vm._v("注释信息:")]
              ),
              _c("el-input", {
                staticStyle: { padding: "5px", border: "none" },
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 11, maxRows: 11 },
                  placeholder: "请输入内容",
                },
                model: {
                  value: _vm.list.Comment[0].data,
                  callback: function ($$v) {
                    _vm.$set(_vm.list.Comment[0], "data", $$v)
                  },
                  expression: "list.Comment[0].data",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("p", { staticClass: "grayfont" }, [
        _vm._v(
          " 谷歌浏览器，设置-高级设置-自定义字体，将字体下限调至最小。打印请去除页眉页脚并调整边距。 "
        ),
      ]),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: _vm.printObj,
              expression: "printObj",
            },
          ],
          on: { click: _vm.beforePrint },
        },
        [_vm._v("打印")]
      ),
      _c("el-button", { on: { click: _vm.updateForm } }, [_vm._v("保存")]),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              _vm.showDelete = true
            },
          },
        },
        [_vm._v("删除")]
      ),
      _c("el-button", { on: { click: _vm.generate_invoce } }, [
        _vm._v("生成发票"),
      ]),
      _c("el-button", { on: { click: _vm.FailureList } }, [_vm._v("历史")]),
      _c("div", { staticClass: "inline" }),
      _c(
        "router-link",
        {
          attrs: {
            to: {
              path: "table",
              query: {
                filters: _vm.listQuery, // orderNum : this.searchData.orderNo
              },
            },
          },
        },
        [_c("el-button", { attrs: { type: "button" } }, [_vm._v("返回")])],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "filter",
          attrs: { visible: _vm.showFailureList, title: "history" },
          on: {
            "update:visible": function ($event) {
              _vm.showFailureList = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.failureList,
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "ID", width: "95" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Company" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.customerInfo.company) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "SN", width: "110", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.productInfo.SN) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Failure", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.failureDescription) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "analysis", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.analysis) +
                            "|" +
                            _vm._s(scope.row.sigmatekReport) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "created_at",
                  label: "返修日期",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.productInfo.return)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "created_at",
                  label: "发货日期",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.productInfo.delivery)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "created_at",
                  label: "返回日期",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.Date))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showFailureList = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDelete, title: "Are you sure" },
          on: {
            "update:visible": function ($event) {
              _vm.showDelete = $event
            },
          },
        },
        [
          _c("p", [_vm._v("确认删除")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteForm } },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showDelete = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { attrs: { id: "printTest" } },
        [
          _c("img", {
            staticClass: "log",
            attrs: { src: require("../../../Img/log.jpg") },
          }),
          _vm._m(0),
          _c("div", [
            _c("h6", [_vm._v("客户信息| Customer Information:")]),
            _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
              _c("tr", [
                _c("td", { staticClass: "clomun1" }, [_vm._v("公司| Company")]),
                _c(
                  "td",
                  { staticClass: "clomun7" },
                  [
                    _c("el-input", {
                      staticClass: "edit-input",
                      attrs: {
                        list: "CompanyList",
                        type: "text",
                        size: "small",
                      },
                      on: { change: _vm.onCompanyChange },
                      model: {
                        value: _vm.list.customerInfo.company,
                        callback: function ($$v) {
                          _vm.$set(_vm.list.customerInfo, "company", $$v)
                        },
                        expression: "list.customerInfo.company",
                      },
                    }),
                    _c(
                      "datalist",
                      { attrs: { id: "CompanyList" } },
                      _vm._l(_vm.customerInfo, function (item) {
                        return _c("option", [
                          _vm._v(" " + _vm._s(item["company"]) + " "),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                ),
                _c("td", { staticClass: "clomun4" }, [
                  _vm._v("联系人| Contactor"),
                ]),
                _c(
                  "td",
                  [
                    _c("el-input", {
                      staticClass: "edit-input",
                      attrs: {
                        list: "ContactsList",
                        type: "text",
                        size: "small",
                      },
                      on: {
                        change: _vm.onContactsChange,
                        focus: function ($event) {
                          _vm.list.customerInfo.contactor = ""
                        },
                      },
                      model: {
                        value: _vm.list.customerInfo.contactor,
                        callback: function ($$v) {
                          _vm.$set(_vm.list.customerInfo, "contactor", $$v)
                        },
                        expression: "list.customerInfo.contactor",
                      },
                    }),
                    _c(
                      "datalist",
                      { attrs: { id: "ContactsList" } },
                      _vm._l(_vm.contacts, function (item) {
                        return _c("option", [
                          _vm._v(" " + _vm._s(item["Name"]) + " "),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("td", [_vm._v("电话| Tel.")]),
                _c(
                  "td",
                  [
                    _c("el-input", {
                      staticClass: "edit-input",
                      attrs: { type: "text", size: "small" },
                      model: {
                        value: _vm.list.customerInfo.tel,
                        callback: function ($$v) {
                          _vm.$set(_vm.list.customerInfo, "tel", $$v)
                        },
                        expression: "list.customerInfo.tel",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", [_vm._v("传真| Fax.")]),
                _c(
                  "td",
                  [
                    _c("el-input", {
                      staticClass: "edit-input",
                      attrs: { type: "text", size: "small" },
                      model: {
                        value: _vm.list.customerInfo.fax,
                        callback: function ($$v) {
                          _vm.$set(_vm.list.customerInfo, "fax", $$v)
                        },
                        expression: "list.customerInfo.fax",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("td", [_vm._v("地址| Add.")]),
                _c(
                  "td",
                  { attrs: { colspan: "3" } },
                  [
                    _c("el-input", {
                      staticClass: "edit-input",
                      attrs: { type: "text", size: "small" },
                      model: {
                        value: _vm.list.customerInfo.add,
                        callback: function ($$v) {
                          _vm.$set(_vm.list.customerInfo, "add", $$v)
                        },
                        expression: "list.customerInfo.add",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("h6", [_vm._v("产品信息| Product Information:")]),
          _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
            _c("tr", [
              _c("td", { staticClass: "clomun1" }, [
                _vm._v("序列号| Serail No."),
              ]),
              _c(
                "td",
                { staticClass: "clomun2" },
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "textarea", autosize: "", size: "small" },
                    on: { change: _vm.checkLength },
                    model: {
                      value: _vm.list.productInfo.SN,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "SN", $$v)
                      },
                      expression: "list.productInfo.SN",
                    },
                  }),
                ],
                1
              ),
              _c("td", { staticClass: "clomun3" }, [
                _vm._v("产品名称| Module"),
              ]),
              _c(
                "td",
                { staticClass: "clomun4" },
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "textarea", autosize: "", size: "small" },
                    model: {
                      value: _vm.list.productInfo.module,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "module", $$v)
                      },
                      expression: "list.productInfo.module",
                    },
                  }),
                ],
                1
              ),
              _c("td", { staticClass: "clomun5" }, [
                _vm._v("产品订货号| Articl No."),
              ]),
              _c(
                "td",
                { staticClass: "clomun6" },
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "textarea", autosize: "", size: "small" },
                    on: { change: _vm.checkArticlNo },
                    model: {
                      value: _vm.list.productInfo.articl,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "articl", $$v)
                      },
                      expression: "list.productInfo.articl",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [_vm._v("硬件版本| Hardware.")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.list.productInfo.hardware,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "hardware", $$v)
                      },
                      expression: "list.productInfo.hardware",
                    },
                  }),
                ],
                1
              ),
              _c("td", [_vm._v("软件版本| Software.")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.list.productInfo.software,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "software", $$v)
                      },
                      expression: "list.productInfo.software",
                    },
                  }),
                ],
                1
              ),
              _c("td", [_vm._v("返修次数| Return Times.")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.list.times,
                      callback: function ($$v) {
                        _vm.$set(_vm.list, "times", $$v)
                      },
                      expression: "list.times",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [_vm._v("退货订单号| Report No.")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "textarea", autosize: "", size: "small" },
                    model: {
                      value: _vm.list.productInfo.report,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "report", $$v)
                      },
                      expression: "list.productInfo.report",
                    },
                  }),
                ],
                1
              ),
              _c("td", [_vm._v("物料号| Parts No.")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "textarea", autosize: "", size: "small" },
                    model: {
                      value: _vm.list.productInfo.parts,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "parts", $$v)
                      },
                      expression: "list.productInfo.parts",
                    },
                  }),
                ],
                1
              ),
              _c("td", [_vm._v("零件编号| PO No.")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "textarea", autosize: "", size: "small" },
                    model: {
                      value: _vm.list.productInfo.po,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "po", $$v)
                      },
                      expression: "list.productInfo.po",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [_vm._v("返修日期| Failure Date")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input date",
                    attrs: { type: "date", size: "small" },
                    model: {
                      value: _vm.list.productInfo.return,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "return", $$v)
                      },
                      expression: "list.productInfo.return",
                    },
                  }),
                ],
                1
              ),
              _c("td", [_vm._v("发货日期| Delivery Date")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input date",
                    attrs: { type: "date", size: "small" },
                    on: { change: _vm.DeliveryChange },
                    model: {
                      value: _vm.list.productInfo.delivery,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "delivery", $$v)
                      },
                      expression: "list.productInfo.delivery",
                    },
                  }),
                ],
                1
              ),
              _c("td", [_vm._v("质保到期日| Warranty Date")]),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "edit-input date",
                    attrs: { type: "date", size: "small" },
                    model: {
                      value: _vm.list.productInfo.warranty,
                      callback: function ($$v) {
                        _vm.$set(_vm.list.productInfo, "warranty", $$v)
                      },
                      expression: "list.productInfo.warranty",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("h6", { staticStyle: { display: "inline-block" } }, [
            _vm._v("故障信息| Failure Description:"),
          ]),
          _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
            _c("tr", [
              _c(
                "td",
                { staticClass: "Failure" },
                [
                  _c("el-input", {
                    ref: "failure",
                    staticClass: "edit-input1 edit-input",
                    attrs: { type: "textarea", size: "small" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          ) &&
                          _vm._k(
                            $event.keyCode,
                            "delete",
                            [8, 46],
                            $event.key,
                            ["Backspace", "Delete", "Del"]
                          )
                        ) {
                          return null
                        }
                        return _vm.resizeHeight($event)
                      },
                    },
                    model: {
                      value: _vm.list.failureDescription,
                      callback: function ($$v) {
                        _vm.$set(_vm.list, "failureDescription", $$v)
                      },
                      expression: "list.failureDescription",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("h6", { staticStyle: { display: "inline-block" } }, [
            _vm._v("维修信息| Analysis:"),
          ]),
          _vm.PrintHidden
            ? _c(
                "el-select",
                {
                  staticClass: "faultclass",
                  attrs: {
                    placeholder: "请选择类型",
                    size: "mini",
                    loading: _vm.classloading,
                  },
                  on: { focus: _vm.getclasslist },
                  model: {
                    value: _vm.list.faultclass,
                    callback: function ($$v) {
                      _vm.$set(_vm.list, "faultclass", $$v)
                    },
                    expression: "list.faultclass",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.PrintHidden
            ? _c(
                "el-select",
                {
                  staticClass: "faultclass",
                  attrs: {
                    placeholder: "请选择类型",
                    size: "mini",
                    loading: _vm.classloading,
                  },
                  on: { focus: _vm.getSecondclasslist },
                  model: {
                    value: _vm.list.faultSecondclass,
                    callback: function ($$v) {
                      _vm.$set(_vm.list, "faultSecondclass", $$v)
                    },
                    expression: "list.faultSecondclass",
                  },
                },
                _vm._l(_vm.Secondoptions, function (seconditem) {
                  return _c("el-option", {
                    key: seconditem.secondvalue,
                    attrs: {
                      label: seconditem.secondlabel,
                      value: seconditem.secondvalue,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.PrintHidden && _vm.list.Status
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "font-size": "small",
                    "margin-left": "20px",
                  },
                },
                [_vm._v(" 状态: " + _vm._s(_vm.list.Status) + " ")]
              )
            : _vm._e(),
          _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
            _c("tr", [
              _c(
                "td",
                { staticClass: "Analysis" },
                [
                  _c("el-input", {
                    ref: "analysis",
                    staticClass: "edit-input2 edit-input",
                    attrs: { type: "textarea", size: "small" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          ) &&
                          _vm._k(
                            $event.keyCode,
                            "delete",
                            [8, 46],
                            $event.key,
                            ["Backspace", "Delete", "Del"]
                          )
                        ) {
                          return null
                        }
                        return _vm.resizeHeight($event)
                      },
                    },
                    model: {
                      value: _vm.list.analysis,
                      callback: function ($$v) {
                        _vm.$set(_vm.list, "analysis", $$v)
                      },
                      expression: "list.analysis",
                    },
                  }),
                  _c("el-input", {
                    ref: "analysisEn",
                    staticClass: "edit-input3 edit-input",
                    attrs: { type: "textarea", size: "small" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          ) &&
                          _vm._k(
                            $event.keyCode,
                            "delete",
                            [8, 46],
                            $event.key,
                            ["Backspace", "Delete", "Del"]
                          )
                        ) {
                          return null
                        }
                        return _vm.resizeHeight($event)
                      },
                    },
                    model: {
                      value: _vm.list.sigmatekReport,
                      callback: function ($$v) {
                        _vm.$set(_vm.list, "sigmatekReport", $$v)
                      },
                      expression: "list.sigmatekReport",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "95%",
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
              },
            },
            [
              _vm._m(1),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "2px",
                    display: "flex",
                    "align-items": "center",
                  },
                },
                [
                  _vm.Switch_Panel && _vm.PrintHidden && _vm.list.back
                    ? _c("p", { staticClass: "InfoPrompt" }, [
                        _vm._v("Sigmatek填写"),
                      ])
                    : _vm._e(),
                  !_vm.Switch_Panel && _vm.PrintHidden && _vm.list.back
                    ? _c("p", { staticClass: "InfoPrompt" }, [
                        _vm._v("供应商填写"),
                      ])
                    : _vm._e(),
                  _vm.list.back & _vm.PrintHidden
                    ? _c("el-button", {
                        staticStyle: { padding: "3px", height: "20px" },
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: { click: _vm.SwitchPanel },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          !_vm.Switch_Panel && _vm.PrintHidden && _vm.list.back
            ? _c(
                "table",
                { attrs: { border: "1", cellspacing: "0", id: "repair-list" } },
                [
                  _vm._m(2),
                  _vm._l(
                    _vm.list.SuppliercomponentsInfomation,
                    function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                size: "small",
                                disabled: true,
                              },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                type: "text",
                                size: "small",
                                disabled: true,
                              },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                size: "small",
                                disabled: true,
                              },
                              model: {
                                value: item.badNr,
                                callback: function ($$v) {
                                  _vm.$set(item, "badNr", $$v)
                                },
                                expression: "item.badNr",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                size: "small",
                                disabled: true,
                              },
                              model: {
                                value: item.newNr,
                                callback: function ($$v) {
                                  _vm.$set(item, "newNr", $$v)
                                },
                                expression: "item.newNr",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input input-right",
                              attrs: {
                                type: "text",
                                size: "small",
                                disabled: true,
                              },
                              model: {
                                value: item.count,
                                callback: function ($$v) {
                                  _vm.$set(item, "count", $$v)
                                },
                                expression: "item.count",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input input-right",
                              attrs: {
                                type: "text",
                                size: "small",
                                disabled: true,
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.caluete(item)
                                },
                              },
                              model: {
                                value: item.price,
                                callback: function ($$v) {
                                  _vm.$set(item, "price", $$v)
                                },
                                expression: "item.price",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "font-size": "2.7mm",
                              "font-family": "sans-serif",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "padding-right": "100px",
                                  border: "0px",
                                  height: "2.2mm",
                                  padding: "0",
                                  width: "98%",
                                  color: "#000",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (item.price
                                        ? parseFloat(item.price) *
                                          parseInt(item.count)
                                        : 0
                                      ).toFixed(2)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c("p", { staticClass: "Revertstyle" }, [
                        _vm._v("快递单号:"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.list.SupplierRelated.Supplier_TrackingNumber,
                            expression:
                              "list.SupplierRelated.Supplier_TrackingNumber",
                          },
                        ],
                        staticClass: "Reportinput",
                        attrs: { disabled: true },
                        domProps: {
                          value:
                            _vm.list.SupplierRelated.Supplier_TrackingNumber,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.list.SupplierRelated,
                              "Supplier_TrackingNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("p", { staticClass: "Revertstyle" }, [
                        _vm._v("快递发出时间:"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.list.SupplierRelated.Supplier_DeliveryDate !==
                              null
                              ? _vm.formatDate(
                                  _vm.list.SupplierRelated.Supplier_DeliveryDate
                                )
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                    _vm._m(3),
                    _c("td", [
                      _c("div", { staticClass: "Quoteinput_Supplier" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.list.SupplierRelated.Supplier_TotalPrice !==
                                null
                                ? parseFloat(
                                    _vm.list.SupplierRelated.Supplier_TotalPrice
                                  ).toFixed(2)
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
          (_vm.Switch_Panel && _vm.list.back) || !_vm.list.back
            ? _c(
                "table",
                { attrs: { border: "1", cellspacing: "0", id: "repair-list" } },
                [
                  _vm._m(4),
                  _vm._l(_vm.list.componentsInfomation, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c(
                        "td",
                        {
                          staticClass: "delete",
                          on: {
                            click: function ($event) {
                              return _vm.remove(index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(index + 1))]
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input", {
                            staticClass: "edit-input",
                            attrs: {
                              type: "textarea",
                              autosize: "",
                              size: "small",
                            },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-autocomplete", {
                            staticClass: "edit-input",
                            attrs: {
                              "fetch-suggestions": _vm.ComponentsSearch,
                              "trigger-on-focus": false,
                              "popper-append-to-body": false,
                              type: "textarea",
                              autosize: "",
                              size: "small",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onComponentsChange(index)
                              },
                              select: function ($event) {
                                return _vm.onComponentsChange(index)
                              },
                            },
                            model: {
                              value: item.number,
                              callback: function ($$v) {
                                _vm.$set(item, "number", $$v)
                              },
                              expression: "item.number",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input", {
                            staticClass: "edit-input",
                            attrs: {
                              type: "textarea",
                              autosize: "",
                              size: "small",
                            },
                            model: {
                              value: item.badNr,
                              callback: function ($$v) {
                                _vm.$set(item, "badNr", $$v)
                              },
                              expression: "item.badNr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input", {
                            staticClass: "edit-input",
                            attrs: {
                              type: "textarea",
                              autosize: "",
                              size: "small",
                            },
                            model: {
                              value: item.newNr,
                              callback: function ($$v) {
                                _vm.$set(item, "newNr", $$v)
                              },
                              expression: "item.newNr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input", {
                            staticClass: "edit-input input-right",
                            attrs: { type: "text", size: "small" },
                            model: {
                              value: item.count,
                              callback: function ($$v) {
                                _vm.$set(item, "count", $$v)
                              },
                              expression: "item.count",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("el-input", {
                            staticClass: "edit-input input-right",
                            attrs: { type: "text", size: "small" },
                            on: {
                              blur: function ($event) {
                                return _vm.caluete(item)
                              },
                            },
                            model: {
                              value: item.price,
                              callback: function ($$v) {
                                _vm.$set(item, "price", $$v)
                              },
                              expression: "item.price",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "font-size": "2.5mm",
                            "font-family": "sans-serif",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-right": "100px",
                                border: "0px",
                                height: "2.7mm",
                                padding: "0",
                                width: "98%",
                                color: "#000",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (item.price
                                      ? parseFloat(item.price) *
                                        parseInt(item.count)
                                      : 0
                                    ).toFixed(2)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                  _vm.PrintHidden
                    ? _c("tr", [
                        _c("td", {
                          staticClass: "create",
                          on: { click: _vm.creatNew },
                        }),
                        _c("td", { ref: "Quote", attrs: { colspan: "2" } }, [
                          _c(
                            "p",
                            { staticStyle: { display: "inline-block" } },
                            [_vm._v("总部维修价格:")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.list.productInfo.Quote,
                                expression: "list.productInfo.Quote",
                              },
                            ],
                            staticClass: "Quoteinput",
                            domProps: { value: _vm.list.productInfo.Quote },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.list.productInfo,
                                  "Quote",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c(
                            "p",
                            { staticStyle: { display: "inline-block" } },
                            [_vm._v("€")]
                          ),
                        ]),
                        _c(
                          "td",
                          { ref: "reportNumber", attrs: { colspan: "2" } },
                          [
                            _c(
                              "p",
                              { staticStyle: { display: "inline-block" } },
                              [_vm._v("报告号:")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.list.productInfo.reportNumber,
                                  expression: "list.productInfo.reportNumber",
                                },
                              ],
                              staticClass: "Reportinput",
                              domProps: {
                                value: _vm.list.productInfo.reportNumber,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.list.productInfo,
                                    "reportNumber",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._m(5),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("el-input", {
                              staticClass: "edit-input input-right",
                              attrs: { type: "text", size: "small" },
                              model: {
                                value: _vm.totalPrice,
                                callback: function ($$v) {
                                  _vm.totalPrice = $$v
                                },
                                expression: "totalPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  !_vm.PrintHidden
                    ? _c("tr", [
                        _c("td"),
                        _c("td", { attrs: { colspan: "3" } }),
                        _vm._m(6),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("el-input", {
                              staticClass: "edit-input input-right",
                              attrs: { type: "text", size: "small" },
                              model: {
                                value: _vm.totalPrice,
                                callback: function ($$v) {
                                  _vm.totalPrice = $$v
                                },
                                expression: "totalPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c("h6", { staticClass: "topSpace" }, [_vm._v("备注| Notice:")]),
          _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
            _c("tr", [
              _c("td", { ref: "Notice", staticClass: "Notice" }, [
                _c("p", [_vm._v("维修周期为客户确认之日壹周起")]),
                _c("p", [
                  _vm._v(
                    " Device will be back within 5 working days after customer confirm the repair "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm.list.back & _vm.PrintHidden
            ? _c("h6", [_vm._v("至供应商留言| MessageToSupplier:")])
            : _vm._e(),
          _vm.list.back & _vm.PrintHidden
            ? _c("div", [
                _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "Failure" },
                      [
                        _c("el-input", {
                          staticClass: "edit-input2 edit-input",
                          attrs: { type: "textarea", size: "small" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                ) &&
                                _vm._k(
                                  $event.keyCode,
                                  "delete",
                                  [8, 46],
                                  $event.key,
                                  ["Backspace", "Delete", "Del"]
                                )
                              ) {
                                return null
                              }
                              return _vm.resizeHeight($event)
                            },
                          },
                          model: {
                            value:
                              _vm.list.SupplierRelated
                                .Sigmatek_MessageToSupplier,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.list.SupplierRelated,
                                "Sigmatek_MessageToSupplier",
                                $$v
                              )
                            },
                            expression:
                              "list.SupplierRelated.Sigmatek_MessageToSupplier",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.PrintHidden
            ? _c("div", { staticClass: "bottomInfo" }, [
                _c("span", { staticClass: "sizesmall" }, [
                  _vm._v("退回供应商 "),
                  _c("div", { staticClass: "inlineblock" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.list.back,
                          expression: "list.back",
                        },
                      ],
                      staticClass: "check-input sizesmall",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.list.back)
                          ? _vm._i(_vm.list.back, null) > -1
                          : _vm.list.back,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.list.back,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(_vm.list, "back", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.list,
                                  "back",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.list, "back", $$c)
                          }
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "sizesmall",
                    staticStyle: { "margin-left": "20px" },
                  },
                  [
                    _vm._v("客户可见 "),
                    _c("div", { staticClass: "inlineblock" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.list.customervisiable,
                            expression: "list.customervisiable",
                          },
                        ],
                        staticClass: "check-input sizesmall",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.list.customervisiable)
                            ? _vm._i(_vm.list.customervisiable, null) > -1
                            : _vm.list.customervisiable,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.list.customervisiable,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.list,
                                    "customervisiable",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.list,
                                    "customervisiable",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.list, "customervisiable", $$c)
                            }
                          },
                        },
                      }),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "bottomInfo" }, [
            _c("div", { staticClass: "relative" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("联系人 Contactor: "),
              ]),
              _c(
                "div",
                { staticClass: "writeInput" },
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.list.contactor,
                      callback: function ($$v) {
                        _vm.$set(_vm.list, "contactor", $$v)
                      },
                      expression: "list.contactor",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "area_right" }, [
                _c("span", { staticClass: "title-right" }, [
                  _vm._v("客户签章 Signature: "),
                ]),
                _c(
                  "div",
                  { staticClass: "writeInput" },
                  [
                    _c("el-input", {
                      staticClass: "bottom-input",
                      attrs: { type: "text", size: "small" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "relative" }, [
              _c("div", { staticClass: "inlineblock" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("返回日期 Date: "),
                ]),
                _c(
                  "div",
                  { staticClass: "writeInput" },
                  [
                    _c("el-input", {
                      staticClass: "bottom-input date",
                      attrs: { type: "date", size: "small" },
                      model: {
                        value: _vm.list.Date,
                        callback: function ($$v) {
                          _vm.$set(_vm.list, "Date", $$v)
                        },
                        expression: "list.Date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "area_right" }, [
                _c("span", { staticClass: "title-right" }, [
                  _vm._v("客户签章日期 Date: "),
                ]),
                _c(
                  "div",
                  { staticClass: "writeInput" },
                  [
                    _c("el-input", {
                      staticClass: "bottom-input",
                      attrs: { type: "text", size: "small" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("维修报告"),
      _c("span", { staticClass: "italic" }, [_vm._v("Repairment")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "300px" } }, [
      _c("h6", [_vm._v("更换部件信息| Components Information:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "clomun11" }, [_vm._v("No.")]),
      _c("td", { staticClass: "clomun12" }, [
        _vm._v("物料名称 "),
        _c("br"),
        _vm._v(" Parts"),
      ]),
      _c("td", { staticClass: "clomun3" }, [
        _vm._v(" 物料号 "),
        _c("br"),
        _vm._v(" Articl No."),
      ]),
      _c("td", { staticClass: "clomun4" }, [
        _vm._v(" 故障物料序列号 "),
        _c("br"),
        _vm._v(" Defect Part No."),
      ]),
      _c("td", { staticClass: "clomun15" }, [
        _vm._v("新物料序列号 "),
        _c("br"),
        _vm._v(" New part No."),
      ]),
      _c("td", { staticClass: "clomun17" }, [
        _vm._v("数量 "),
        _c("br"),
        _vm._v(" QTY."),
      ]),
      _c("td", { staticClass: "clomun16" }, [
        _vm._v("单价 "),
        _c("br"),
        _vm._v(" Price"),
      ]),
      _c("td", { staticClass: "clomun16" }, [
        _vm._v("合价 "),
        _c("br"),
        _vm._v(" Price"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "2" } }, [
      _vm._v("供应商合计（含税）"),
      _c("br"),
      _vm._v(" Total Price(INC.VAT.)"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "clomun11" }, [_vm._v("No.")]),
      _c("td", { staticClass: "clomun12" }, [
        _vm._v("物料名称 "),
        _c("br"),
        _vm._v(" Parts"),
      ]),
      _c("td", { staticClass: "clomun3" }, [
        _vm._v(" 物料号 "),
        _c("br"),
        _vm._v(" Articl No."),
      ]),
      _c("td", { staticClass: "clomun4" }, [
        _vm._v(" 故障物料序列号 "),
        _c("br"),
        _vm._v(" Defect Part No."),
      ]),
      _c("td", { staticClass: "clomun15" }, [
        _vm._v("新物料序列号 "),
        _c("br"),
        _vm._v(" New part No."),
      ]),
      _c("td", { staticClass: "clomun17" }, [
        _vm._v("数量 "),
        _c("br"),
        _vm._v(" QTY."),
      ]),
      _c("td", { staticClass: "clomun16" }, [
        _vm._v("单价 "),
        _c("br"),
        _vm._v(" Price"),
      ]),
      _c("td", { staticClass: "clomun16" }, [
        _vm._v("合价 "),
        _c("br"),
        _vm._v(" Price"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "2" } }, [
      _vm._v("合计（含税）"),
      _c("br"),
      _vm._v(" Total Price(INC.VAT.)"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3" } }, [
      _vm._v("合计（含税）"),
      _c("br"),
      _vm._v(" Total Price(INC.VAT.)"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }