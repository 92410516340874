<template>
  <div class="app-container">
    <!-- 注释标签 -->
    <div v-if="PrintHidden" style="border: #000 3px solid; border-radius: 10px;width:300px;height:300px;position:fixed;right:100px;top:300px">
      <h6 style="padding: 5px;padding-bottom: 0px; font-size: large;">注释信息:</h6>
      <el-input type="textarea":autosize="{ minRows: 11, maxRows: 11}" placeholder="请输入内容" v-model="list.Comment[0].data" style="padding:5px;border:none"></el-input>
    </div>
    <p class="grayfont">
      谷歌浏览器，设置-高级设置-自定义字体，将字体下限调至最小。打印请去除页眉页脚并调整边距。
    </p>
    <el-button @click="beforePrint" v-print="printObj">打印</el-button>
    <!-- <el-button v-on:click ="creatForm" >保存</el-button>
    <el-button v-on:click ="newForm" >新建</el-button> -->
    <el-button v-on:click="updateForm">保存</el-button>
    <el-button v-on:click="showDelete = true">删除</el-button>
    <el-button v-on:click="generate_invoce">生成发票</el-button>
    <el-button v-on:click="FailureList">历史</el-button>
    <!-- <el-select style="margin-right:13px" ref = "select" class="faultclass" placeholder="请选择">
      <el-option>1</el-option>
      <el-option>2</el-option>
      <el-option>3</el-option>
    </el-select> -->
    <!-- <el-menu> </el-menu> -->
    <div class="inline"></div>
    <router-link :to="{
      path: 'table',
      query: {
        filters: listQuery, // orderNum : this.searchData.orderNo
      },
    }">
      <el-button type="button">返回</el-button>
    </router-link>
    <el-dialog class="filter" :visible.sync="showFailureList" title="history">
      <el-table v-loading="listLoading" :data="failureList" element-loading-text="Loading" border fit highlight-current-row>
        <el-table-column align="center" label="ID" width="95">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="Company">
          <template slot-scope="scope">
            {{ scope.row.customerInfo.company }}
          </template>
        </el-table-column>

        <el-table-column label="SN" width="110" align="center">
          <template slot-scope="scope">
            {{ scope.row.productInfo.SN }}
          </template>
        </el-table-column>
        <el-table-column label="Failure" align="center">
          <template slot-scope="scope">
            {{ scope.row.failureDescription }}
          </template>
        </el-table-column>
        <el-table-column label="analysis" align="center">
          <template slot-scope="scope">
            {{ scope.row.analysis }}|{{ scope.row.sigmatekReport }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="created_at" label="返修日期" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.productInfo.return }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="created_at" label="发货日期" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.productInfo.delivery }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="created_at" label="返回日期" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.Date }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="showFailureList = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showDelete" title="Are you sure">
      <p>确认删除</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="deleteForm">确认</el-button>
        <el-button type="primary" v-on:click="showDelete = false">取消</el-button>
      </span>
    </el-dialog>

    <div id="printTest">
      <img class="log" src="../../../Img/log.jpg" />
      <h2>维修报告<span class="italic">Repairment</span></h2>

      <div>
        <h6>客户信息| Customer Information:</h6>
        <table border="1" cellspacing="0">
          <tr>
            <td class="clomun1">公司| Company</td>
            <td class="clomun7">
              <el-input list="CompanyList" @change="onCompanyChange" type="text" v-model="list.customerInfo.company" class="edit-input" size="small" />
              <datalist id="CompanyList">
                <option v-for="item in customerInfo">
                  {{ item["company"] }}
                </option>
              </datalist>
            </td>

            <td class="clomun4">联系人| Contactor</td>
            <td>
              <el-input list="ContactsList" @change="onContactsChange" @focus="list.customerInfo.contactor = ''" type="text" v-model="list.customerInfo.contactor" class="edit-input" size="small" />
              <datalist id="ContactsList">
                <option v-for="item in contacts">
                  {{ item["Name"] }}
                </option>
              </datalist>
            </td>

          </tr>
          <tr>
            <td>电话| Tel.</td>
            <td>
              <el-input type="text" v-model="list.customerInfo.tel" class="edit-input" size="small" />
            </td>
            <td>传真| Fax.</td>
            <td>
              <el-input type="text" v-model="list.customerInfo.fax" class="edit-input" size="small" />
            </td>
          </tr>
          <tr>
            <td>地址| Add.</td>
            <td colspan="3">
              <el-input type="text" v-model="list.customerInfo.add" class="edit-input" size="small" />
            </td>
          </tr>
        </table>
      </div>
      <h6>产品信息| Product Information:</h6>
      <table border="1" cellspacing="0">
        <tr>
          <td class="clomun1">序列号| Serail No.</td>
          <td class="clomun2">
            <el-input type="textarea" autosize v-on:change="checkLength" v-model="list.productInfo.SN" class="edit-input" size="small" />
          </td>
          <td class="clomun3">产品名称| Module</td>
          <td class="clomun4">
            <el-input type="textarea" autosize v-model="list.productInfo.module" class="edit-input" size="small" />
          </td>
          <td class="clomun5">产品订货号| Articl No.</td>
          <td class="clomun6">
            <el-input type="textarea" autosize v-on:change="checkArticlNo" v-model="list.productInfo.articl" class="edit-input" size="small" />
          </td>
        </tr>
        <tr>
          <td>硬件版本| Hardware.</td>
          <td>
            <el-input type="text" v-model="list.productInfo.hardware" class="edit-input" size="small" />
          </td>
          <td>软件版本| Software.</td>
          <td>
            <el-input type="text" v-model="list.productInfo.software" class="edit-input" size="small" />
          </td>
          <td>返修次数| Return Times.</td>
          <td>
            <el-input type="text" v-model="list.times" class="edit-input" size="small" />
          </td>
        </tr>
        <tr>
          <td>退货订单号| Report No.</td>
          <td>
            <el-input type="textarea" autosize v-model="list.productInfo.report" class="edit-input" size="small" />
          </td>
          <td>物料号| Parts No.</td>
          <td>
            <el-input type="textarea" autosize v-model="list.productInfo.parts" class="edit-input" size="small" />
          </td>
          <td>零件编号| PO No.</td>
          <td>
            <el-input type="textarea" autosize v-model="list.productInfo.po" class="edit-input" size="small" />
          </td>
        </tr>
        <tr>
          <td>返修日期| Failure Date</td>
          <td>
            <el-input type="date" v-model="list.productInfo.return" class="edit-input date" size="small" />
          </td>
          <td>发货日期| Delivery Date</td>
          <td>
            <el-input type="date" v-model="list.productInfo.delivery" @change="DeliveryChange" class="edit-input date" size="small" />
          </td>
          <td>质保到期日| Warranty Date</td>
          <td>
            <el-input type="date" v-model="list.productInfo.warranty" class="edit-input date" size="small" />
          </td>
        </tr>
      </table>

      <h6 style="display: inline-block">故障信息| Failure Description:</h6>
      <table border="1" cellspacing="0">
        <tr>
          <td class="Failure">
            <el-input ref="failure" @keyup.native.enter.delete="resizeHeight" type="textarea" v-model="list.failureDescription" class="edit-input1 edit-input" size="small" />
          </td>
        </tr>
      </table>

      <h6 style="display: inline-block">维修信息| Analysis:</h6>

      <el-select class="faultclass" v-if="PrintHidden" v-model="list.faultclass" placeholder="请选择类型" size="mini" @focus="getclasslist" :loading="classloading">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select class="faultclass" v-if="PrintHidden" v-model="list.faultSecondclass" placeholder="请选择类型" size="mini" @focus="getSecondclasslist" :loading="classloading">
        <el-option v-for="seconditem in Secondoptions" :key="seconditem.secondvalue" :label="seconditem.secondlabel" :value="seconditem.secondvalue">
        </el-option>
      </el-select>

      <div v-if="PrintHidden && list.Status" style="display: inline-block;font-size: small;margin-left: 20px;">
        状态:
        {{ list.Status }}
      </div>

      <table border="1" cellspacing="0">
        <tr>
          <td class="Analysis">
            <el-input ref="analysis" type="textarea" @keyup.native.enter.delete="resizeHeight" v-model="list.analysis" class="edit-input2 edit-input" size="small" />
            <el-input ref="analysisEn" type="textarea" @keyup.native.enter.delete="resizeHeight" v-model="list.sigmatekReport" class="edit-input3 edit-input" size="small" />
          </td>
        </tr>
      </table>

      <div style="
          width: 95%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <div style="width: 300px">
          <h6>更换部件信息| Components Information:</h6>
        </div>
        <div style="margin-top: 2px;display: flex;align-items: center;">
          <p class="InfoPrompt" v-if="Switch_Panel && PrintHidden && list.back">Sigmatek填写</p>
          <p class="InfoPrompt" v-if="!Switch_Panel && PrintHidden && list.back">供应商填写</p>
          <el-button icon="el-icon-refresh" size="mini" style="padding: 3px;height: 20px;" v-if="list.back & PrintHidden" @click="SwitchPanel" />
        </div>
      </div>

      <!-- 供应商填写的表格，我们无法修改 -->
      <table border="1" cellspacing="0" id="repair-list" v-if="!Switch_Panel && PrintHidden && list.back">
        <tr>
          <td class="clomun11">No.</td>
          <td class="clomun12">物料名称 <br> Parts</td>
          <td class="clomun3"> 物料号 <br> Articl No.</td>
          <td class="clomun4"> 故障物料序列号 <br> Defect Part No.</td>
          <td class="clomun15">新物料序列号 <br> New part No.</td>
          <td class="clomun17">数量 <br> QTY.</td>
          <td class="clomun16">单价 <br> Price</td>
          <td class="clomun16">合价 <br> Price</td>
        </tr>

        <tr v-for="(item, index) in list.SuppliercomponentsInfomation" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            <el-input type="textarea" autosize v-model="item.name" class="edit-input" size="small" :disabled="true" />
          </td>
          <td>
            <el-input type="text" v-model="item.number" class="edit-input" size="small" :disabled="true" />
          </td>
          <td>
            <el-input type="textarea" autosize v-model="item.badNr" class="edit-input" size="small" :disabled="true" />
          </td>
          <td>
            <el-input type="textarea" autosize v-model="item.newNr" class="edit-input" size="small" :disabled="true" />
          </td>
          <td>
            <el-input type="text" v-model="item.count" class="edit-input input-right" size="small" :disabled="true" />
          </td>
          <td>
            <el-input @blur="caluete(item)" type="text" v-model="item.price" class="edit-input input-right" size="small" :disabled="true" />
          </td>
          <td style="text-align:right; font-size: 2.7mm; font-family: sans-serif;">
            <div style="  padding-right: 100px;
                          border: 0px;
                          height: 2.2mm;
                          padding: 0;
                          width: 98%;
                          color: #000;
                        ">
              {{ (item.price ? parseFloat(item.price) * parseInt(item.count) : 0).toFixed(2) }}
            </div>
          </td>
        </tr>

        <!-- 退回供应商状态，供应商填写部分,不可修改 -->
        <tr>
          <!-- <td colspan="2">
            <p class="Revertstyle">供应商维修价格:</p>
            <input
              class="Quoteinput"
              :disabled="true"
              v-model="list.SupplierRelated.Supplier_TotalPrice"
            />
            <p class="Revertstyle">€</p>
          </td> -->

          <td colspan="3">
            <p class="Revertstyle">快递单号:</p>
            <input class="Reportinput" :disabled="true" v-model="list.SupplierRelated.Supplier_TrackingNumber" />
          </td>

          <td colspan="2">
            <p class="Revertstyle">快递发出时间:</p>
            <!-- <el-input
              type="date"
              :disabled="true"
              v-model="list.SupplierRelated.Supplier_DeliveryDate"
              class="edit-input Reportinput"
              size="small"
              value-format="timestamp"
            /> -->
            {{ list.SupplierRelated.Supplier_DeliveryDate !== null ? formatDate(list.SupplierRelated.Supplier_DeliveryDate) : '-' }}
          </td>

          <td colspan="2">供应商合计（含税）<br> Total Price(INC.VAT.)</td>
          <td>
            <div class="Quoteinput_Supplier">
              {{ list.SupplierRelated.Supplier_TotalPrice !== null ? parseFloat(list.SupplierRelated.Supplier_TotalPrice).toFixed(2) : '-' }}
            </div>
          </td>
        </tr>
      </table>

      <!-- 我们填写的表格 -->
      <table border="1" cellspacing="0" id="repair-list" v-if="(Switch_Panel && list.back) || (!list.back)">
        <tr>
          <td class="clomun11">No.</td>
          <td class="clomun12">物料名称 <br> Parts</td>
          <td class="clomun3"> 物料号 <br> Articl No.</td>
          <td class="clomun4"> 故障物料序列号 <br> Defect Part No.</td>
          <td class="clomun15">新物料序列号 <br> New part No.</td>
          <td class="clomun17">数量 <br> QTY.</td>
          <td class="clomun16">单价 <br> Price</td>
          <td class="clomun16">合价 <br> Price</td>
        </tr>

        <tr v-for="(item, index) in list.componentsInfomation" :key="index">
          <td class="delete" v-on:click="remove(index)">{{ index + 1 }}</td>
          <td>
            <el-input type="textarea" autosize v-model="item.name" class="edit-input" size="small" />
          </td>
          <td>
            <el-autocomplete :fetch-suggestions="ComponentsSearch" :trigger-on-focus="false" :popper-append-to-body="false" type="textarea" autosize @change="onComponentsChange(index)"
              @select="onComponentsChange(index)" v-model="item.number" class="edit-input" size="small" />
          </td>
          <td>
            <el-input type="textarea" autosize v-model="item.badNr" class="edit-input" size="small" />
          </td>
          <td>
            <el-input type="textarea" autosize v-model="item.newNr" class="edit-input" size="small" />
          </td>
          <td>
            <el-input type="text" v-model="item.count" class="edit-input input-right" size="small" />
          </td>
          <td>
            <el-input @blur="caluete(item)" type="text" v-model="item.price" class="edit-input input-right" size="small" />
          </td>
          <td style=" text-align:right; font-size: 2.5mm; font-family: sans-serif;">
            <div style="  padding-right: 100px;
                          border: 0px;
                          height: 2.7mm;
                          padding: 0;
                          width: 98%;
                          color: #000;
                        ">
              {{ (item.price ? parseFloat(item.price) * parseInt(item.count) : 0).toFixed(2) }}
            </div>
          </td>
        </tr>

        <!-- 非打印时 -->
        <tr v-if="PrintHidden">
          <td class="create" v-on:click="creatNew"></td>

          <td colspan="2" ref="Quote">
            <p style="display: inline-block">总部维修价格:</p>
            <input class="Quoteinput" v-model="list.productInfo.Quote" />
            <p style="display: inline-block">€</p>
          </td>

          <td colspan="2" ref="reportNumber">
            <p style="display: inline-block">报告号:</p>
            <input class="Reportinput" v-model="list.productInfo.reportNumber" />
          </td>

          <td colspan="2">合计（含税）<br> Total Price(INC.VAT.)</td>
          <td class="text-right">
            <el-input type="text" v-model="totalPrice" class="edit-input input-right" size="small" />
          </td>
        </tr>


        <!-- 打印时 -->
        <tr v-if="!PrintHidden">
          <td></td>
          <td colspan="3"></td>
          <td colspan="3">合计（含税）<br> Total Price(INC.VAT.)</td>
          <td class="text-right">
            <el-input type="text" v-model="totalPrice" class="edit-input input-right" size="small" />
          </td>
        </tr>
      </table>

      <h6 class="topSpace">备注| Notice:</h6>
      <table border="1" cellspacing="0">
        <tr>
          <td class="Notice" ref="Notice">
            <p>维修周期为客户确认之日壹周起</p>
            <p>
              Device will be back within 5 working days after customer confirm
              the repair
            </p>
          </td>
        </tr>
      </table>

      <h6 v-if="list.back & PrintHidden">至供应商留言| MessageToSupplier:</h6>
      <div v-if="list.back & PrintHidden">
        <table border="1" cellspacing="0">
          <tr>
            <td class="Failure">
              <el-input type="textarea" @keyup.native.enter.delete="resizeHeight" v-model="list.SupplierRelated.Sigmatek_MessageToSupplier" class="edit-input2 edit-input" size="small" />
            </td>
          </tr>
        </table>
      </div>

      <div class="bottomInfo" v-if="PrintHidden">
        <span class="sizesmall">退回供应商
          <div class="inlineblock">
            <input type="checkbox" v-model="list.back" class="check-input sizesmall" />
          </div>
        </span>
        <span class="sizesmall" style="margin-left:20px">客户可见
          <div class="inlineblock">
            <input type="checkbox" v-model="list.customervisiable" class="check-input sizesmall" />
          </div>
        </span>
        <!-- <span class="left_space">第<div class="timesInput"><el-input type="text" v-model="list.times"  class="bottom-input" size="small" /></div>
          次返回</span> -->
      </div>

      <div class="bottomInfo">
        <div class="relative">
          <span class="title">联系人 Contactor: </span>
          <div class="writeInput">
            <el-input type="text" v-model="list.contactor" class="bottom-input" size="small" />
          </div>

          <div class="area_right">
            <span class="title-right">客户签章 Signature: </span>
            <div class="writeInput">
              <el-input type="text" class="bottom-input" size="small" />
            </div>
          </div>
        </div>
        <div class="relative">
          <div class="inlineblock">
            <span class="title">返回日期 Date: </span>
            <div class="writeInput">
              <el-input type="date" v-model="list.Date" class="bottom-input date" size="small" />
            </div>
          </div>
          <div class="area_right">
            <span class="title-right">客户签章日期 Date: </span>
            <div class="writeInput">
              <el-input type="text" class="bottom-input" size="small" />
            </div>
          </div>
        </div>
      </div>

      <!-- <div   class="bottomArea">
         <table class="noBorder">
           <tr>
             <td>宁波西格玛泰克自动化设备有限公司</td>
             <td>+8657487753085</td>
             <td class="text_right">office@sigmatek-automation.com</td>
           </tr>
           <tr>
             <td>惊驾路555号栋805室 315040 宁波 中国</td>
             <td>+8657487753065</td>
             <td class="text_right">www.sigmatek-automation.com</td>
           </tr>
         </table>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getList, GenerateInvoice_SB } from "@/api/table";
import
{
  fetchList,
  fetchArticle,
  fetchPv,
  createArticle,
  updateArticle,
  deleteArticle,
  getCustomerList,
  getComponentsList,
} from "@/api/article";
import { getsummarylist } from "@/api/summary";
import { getfaultclasslist } from "@/api/faultclass";
import { MessageBox, Message } from "element-ui";
export default {
  name: "InlineEditTable",
  filters: {
    statusFilter(status)
    {
      const statusMap = {
        published: "success",
        draft: "info",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data()
  {
    return {
      PrintHidden: true,
      SupplierPrintHidden: true,
      printObj: {
        id: "printTest",
        closeCallback(vue)
        {
          vue.SupplierPrintHidden = true;
          vue.PrintHidden = true;
          vue.ClearRow();
          // vue.$refs.Quote.style.visibility = "visible";
          // vue.$refs.reportNumber.style.visibility = "visible";
          // vue.$refs.fault.$el.style.visibility = "visible";
          // vue.$refs.fault1.$el.style.visibility = "visible";
          // vue.$refs.secondfault.$el.style.visibility = "visible";
        },
      },
      id: null,
      showDelete: false,
      listQuery: null,
      showFailureList: false,
      failureList: null,

      customerInfo: [{
        company: null,
        companyEn: null,
        fax: null,
        add: null,
        WarrantyDays: null,
        OrderNumberAbbr: null,
        contacts: [{
          Name: null,
          Tel: null,
          SeparateAddress: null,
          customers: false,
        }]
      }],
      ContactsAutoFlag: false,
      contacts: [{
        Name: null,
        Tel: null,
        SeparateAddress: null,
        Preferences: false,
      }],

      componentsInfo: [
        {
          parts: null,
          number: null,
          badNr: null,
          newNr: null,
          price: null,
        },
      ],
      Switch_Panel: true,
      classloading: false,
      options: [],
      Secondoptions: [],
      showSecondMenu: false,
      list: {
        Comment:[""],
        customerInfo: {
          company: null,
          contactor: null,
          companyEn: null,
          tel: null,
          fax: null,
          add: null,
          WarrantyDays: null,
          OrderNumberAbbr: null,
        },
        productInfo: {
          SN: null,
          module: null,
          articl: null,
          hardware: null,
          software: null,
          report: null,
          parts: null,
          po: null,
          return: null,
          delivery: null,
          warranty: null,

          Quote: null,
          reportNumber: null,
        },
        failureDescription: null,
        faultclass: null,
        faultSecondclass: null,
        analysis: null,
        componentsInfomation: [
          {
            name: null,
            count: null,
            number: null,
            badNr: null,
            newNr: null,
            price: null,
          },
        ],
        SuppliercomponentsInfomation: [
          {
            name: null,
            count: null,
            number: null,
            badNr: null,
            newNr: null,
            price: null,
          },
        ],
        back: false,
        SupplierRelated: {
          Sigmatek_MessageToSupplier: null,
          Supplier_Complete: null,
          Supplier_TotalPrice: null,
          Supplier_TrackingNumber: null,
          Supplier_DeliveryDate: null,
        },
        times: null,
        contactor: "null",
        Date: null,
        sigmatekReport: null,
        ups: {
          sendType: null,
          sendDate: null,
          backType: null,
          backDate: null,
        },
      },
      
      input: null,
      input2: null,
      input3: false,
      listLoading: true,
      items: [
        {
          name: null,
          number: null,
          badNr: null,
          newNr: null,
          price: null,
        },
      ],
      listQuery: {
        page: 1,
        limit: 10,
      },
      articlInfo: null,
    };
  },
  watch: {
    // 'list.productInfo.parts'(val) {
    //   let a = val;
    // }
    "list.faultclass"(newval, oldval)
    {
      if (oldval !== newval && newval) {
        // this.list.faultSecondclass = null;
        this.showSecondMenu = true;
      }
    },
    "list.productInfo.articl"(newval, oldval)
    {
      if (newval == "" || newval == null || newval == undefined) {
        this.list.faultclass = null;
        this.list.faultSecondclass = null;
        this.showSecondMenu = false;
      }
    },
  },
  computed: {
    totalPrice: function ()
    {
      let total = 0;
      let haveo = false;
      for (let i = 0; i < this.list.componentsInfomation.length; i++) {
        if (
          (this.list.componentsInfomation[i].price !== "") &
          (this.list.componentsInfomation[i].price !== null)
        ) {
          total += parseFloat(this.list.componentsInfomation[i].price) * this.list.componentsInfomation[i].count;
          if (this.list.componentsInfomation[i].price.indexOf("€") != -1) {
            haveo = true;
          }
        }
      }
      if (haveo) {
        return total.toFixed(2) + "€";
      } else {
        return total.toFixed(2);
      }
    },

  },
  mounted: function ()
  {
    this.listQuery = this.$route.query.filters;
    this.id = this.$route.query.key;
    this.getList();
  },

  created()
  {
    this.getCustomerInfo();
    this.getComponentsInfo();
  },

  methods: {
    generate_invoce()
    {
      GenerateInvoice_SB({
        productInfo: this.list.productInfo,
        id: this.list._id,
      }).then((response) => response.arrayBuffer())
        .then((arrayBuffer) =>
        {
          const blob = new Blob([arrayBuffer], {
            type: "application/octet-stream",
          });
          // 创建一个下载链接
          const url = URL.createObjectURL(blob);
          // 创建一个隐藏的 <a> 标签
          const link = document.createElement("a");
          link.href = url;
          let data = new Date();
          let year = data.getFullYear();
          let month = data.getMonth() + 1;
          let day = data.getDate();
          link.download = `INVOICE_${year}${month < 10 ? '0' + month : month}${day < 10 ? '0' + day : day}_001.pdf`;
          // 模拟点击下载链接
          link.click();
          // 清理释放资源
          URL.revokeObjectURL(url);
        });
    },
    async getList()
    {
      if (!this.id) {
        this.id = "";
      }
      this.listLoading = true;
      const { data } = await fetchArticle(this.id);
      if(!data.Comment || data.Comment.length == 0){
        data.Comment = [{data:""}]
      }
      //如果有componentsInfomation字段

      if (data.componentsInfomation) {
        data.componentsInfomation.forEach((item) =>
        {
          //如果item没有count、undefined、null、''、0、false,不是数字，都改为1
          if (
            !item.count ||
            isNaN(item.count) ||
            item.count == "" ||
            item.count == 0 ||
            item.count == false
          ) {
            item.count = 1;
          }
        });
      }

      Object.assign(this.list, data);
      console.log(this.list)
      setTimeout(() =>
      {
        this.resize();
        this.listLoading = false;
      }, 0.5 * 1000);
      this.list.contactor = "Kyle";
      if (this.list.customerInfo.company != "") {                             //对非新建的表单，自动初始化contacts表单
        for (var i = 0; i < this.customerInfo.length; i++) {
          if (this.customerInfo[i].company === this.list.customerInfo.company) {
            this.contacts = this.customerInfo[i].contacts;
            // break;  // 找到匹配的company后，结束循环
          }
        }
      }
    },

    caluete(item)
    {
    //  console.log(item.price);
      if (item.price) {
        if (item.price.indexOf("€") != -1) {
          item.price = parseFloat(item.price).toFixed(2) + "€";
        } else item.price = parseFloat(item.price).toFixed(2);
      }
    },

    async FailureList()
    {
      this.showFailureList = true;
      const { data } = await getList({
        page: 1,
        limit: 2000,
        filters: "({'productInfo.SN':'" + this.list.productInfo.SN + "'})",
      });
      this.failureList = data.list;
    },

    async checkLength()
    {
      const { data } = await getList({
        page: 1,
        limit: 2000,
        filters: "({'productInfo.SN':'" + this.list.productInfo.SN + "'})",
      });
      var list = data.list;
      if (list.length > 0) {
        this.list.customerInfo = list[0].customerInfo;
        this.list.customerInfo.company = list[0].customerInfo.company;
        this.list.productInfo.articl = list[0].productInfo.articl;
        this.list.productInfo.hardware = list[0].productInfo.hardware;
        this.list.productInfo.software = list[0].productInfo.software;
        this.list.productInfo.module = list[0].productInfo.module;
        this.list.productInfo.delivery = list[0].productInfo.delivery;
        this.list.productInfo.warranty = list[0].productInfo.warranty;
        this.list.times = list.length + 1;
      } else {
        this.list.times = 1;
      }
    }, //输入序列号联想到过往产品

    async checkArticlNo()
    {
      if (this.list.productInfo.articl != "") {
        let datafilters = "({'number':'" + this.list.productInfo.articl + "'})";
        const { data } = await getComponentsList({
          page: 1,
          limit: 10,
          filters: datafilters,
        });
        var datafilterslist = data.list;
        if (datafilterslist.length > 0) {
          this.list.productInfo.module = datafilterslist[0].parts;
        } else {
          this.list.productInfo.module = null;
        }
      } else {
        this.list.productInfo.module = null;
      }
      this.$forceUpdate();
    }, //输入产品订货号联想到产品名称
    resize()
    {
      this.$refs.failure.$refs.textarea.style.height =
        this.$refs.failure.$refs.textarea.scrollHeight + "px";
      this.$refs.analysis.$refs.textarea.style.height =
        this.$refs.analysis.$refs.textarea.scrollHeight + "px";
      this.$refs.analysisEn.$refs.textarea.style.height =
        this.$refs.analysisEn.$refs.textarea.scrollHeight + "px";
    },

    onCompanyChange()
    {
      if (this.list.customerInfo.company != "") {
        for (var i = 0; i < this.customerInfo.length; i++) {
          if (this.customerInfo[i].company === this.list.customerInfo.company) {
            this.list.customerInfo.fax = this.customerInfo[i].fax;
            this.list.customerInfo.companyEn = this.customerInfo[i].companyEn;
            this.list.customerInfo.WarrantyDays = this.customerInfo[i].WarrantyDays;
            this.list.customerInfo.OrderNumberAbbr = this.customerInfo[i].OrderNumberAbbr;
            this.list.customerInfo.add = this.customerInfo[i].add;
            this.contacts = this.customerInfo[i].contacts;
            this.ContactsAutoFlag = true;
            this.onContactsChange();
           // console.log(this.list.customerInfo);
            break;  // 找到匹配的company后，结束循环
          }
        }
      } else {
        this.contacts = [];
        this.list.customerInfo.contactor = null;
        this.list.customerInfo.companyEn = null;
        this.list.customerInfo.tel = null;
        this.list.customerInfo.fax = null;
        this.list.customerInfo.add = null;
        this.list.customerInfo.WarrantyDays = null;
        this.list.customerInfo.OrderNumberAbbr = null;
      }
    }, //输入公司，自动填入其他信息
    onContactsChange()
    {
      if (this.list.customerInfo.contactor != "") {
        if (this.ContactsAutoFlag) {
          for (var i = 0; i < this.contacts.length; i++) {
            if (this.contacts[i].Preferences) {
              this.list.customerInfo.contactor = this.contacts[i].Name;
              this.list.customerInfo.tel = this.contacts[i].Tel;
              if (this.contacts[i].SeparateAddress === '' || this.contacts[i].SeparateAddress === null || this.contacts[i].SeparateAddress === undefined) {
              } else {
                this.list.customerInfo.add = this.contacts[i].SeparateAddress;
              }
              this.ContactsAutoFlag = false;
              break;
            }
          }
        } else {
          for (var i = 0; i < this.contacts.length; i++) {
            if (this.contacts[i].Name === this.list.customerInfo.contactor) {
              this.list.customerInfo.tel = this.contacts[i].Tel;
              if (this.contacts[i].SeparateAddress === '' || this.contacts[i].SeparateAddress === null || this.contacts[i].SeparateAddress === undefined) {
              } else {
                this.list.customerInfo.add = this.contacts[i].SeparateAddress;
              }
              break;
            }
          }
        }
      } else {
        this.list.customerInfo.tel = null;
        this.list.customerInfo.add = null;
      }
    },

    ComponentsSearch(ComponentsString, callback)
    {
      const restaurants = [];
      // 根据用户输入的 queryString 进行匹配
      const MatchedComponentsInfo = this.componentsInfo.filter((info) =>
      {
        return (
          info.number &&
          info.number.toLowerCase().includes(ComponentsString.toLowerCase())
        );
      });
      // 将匹配的结果添加到建议列表中
      MatchedComponentsInfo.forEach((info) =>
      {
        restaurants.push({ value: info.number, label: info.number });
      });
      // 将建议列表传递给回调函数
      callback(restaurants);
    },

    SwitchPanel()
    {
      if (this.Switch_Panel) {
        this.Switch_Panel = false;
      } else {
        this.Switch_Panel = true;
      }
    },

    async getclasslist()
    {
      if (
        this.list.productInfo.articl == undefined ||
        this.list.productInfo.articl == null
      ) {
        return;
      }
      this.classloading = true;
      //console.log(this.list.productInfo.articl);
      const { data } = await getsummarylist({
        page: 1,
        limit: 1,
        filters: "({'Number' :'" + this.list.productInfo.articl + "'})",
      });

      if (data.total >= 1) {
        let _options = [];
        for (var p in data.list[0].Problem) {
          let obj = {
            value: p,
            label: p,
          };
          _options.push(obj);
        }
        this.options = _options;
        this.articlInfo = data.list[0].Problem;
      } else {
        this.options = [
          {
            value: "查询不到信息",
            label: "查询不到信息",
          },
        ];
      }
      this.classloading = false;

      // this.$forceUpdate();
    },
    getSecondclasslist()
    {
      if (this.articlInfo == null) {
        this.Secondoptions = [
          {
            value: "查询不到信息",
            label: "查询不到信息",
          },
        ];
        return;
      } else {
        let _options = [];

        for (var i = 0; i < this.articlInfo[this.list.faultclass].length; i++) {
          let obj = {
            secondvalue: this.articlInfo[this.list.faultclass][i],
            secondlabel: this.articlInfo[this.list.faultclass][i],
          };
          _options.push(obj);
        }
        this.Secondoptions = _options;
      }
    },
    onComponentsChange: function (index)
    {
      for (var i = 0; i < this.componentsInfo.length; i++) {
        if (
          this.componentsInfo[i].number ==
          this.list.componentsInfomation[index].number
        ) {
          this.list.componentsInfomation[index].name =
            this.componentsInfo[i].parts;
        }
        //  if  ( this.list.componentsInfomation[index].name.indexOf("_") !=-1){
        //    const name = this.list.componentsInfomation[index].name.split("_");
        //    const parts = name[0];
        //    const Comment = name[1];
        //    for (var i=0;i<this.componentsInfo.length;i++){
        //      if (this.componentsInfo[i].parts == parts ){
        //        this.list.componentsInfomation[index].name =  this.componentsInfo[i].parts;
        //        this.list.componentsInfomation[index].number =  this.componentsInfo[i].number;
        //       //  this.list.componentsInfomation[index].badNr =  this.componentsInfo[i].badNr;
        //       //  this.list.componentsInfomation[index].newNr =  this.componentsInfo[i].newNr;
        //       //  this.list.componentsInfomation[index].price =  this.componentsInfo[i].price;
        //      }
        //    }
        //  }
        //  else
        //  {
        //     const parts = this.list.componentsInfomation[index].name;
        //     for (var i=0;i<this.componentsInfo.length;i++){
        //      if (this.componentsInfo[i].parts == parts ){
        //        this.list.componentsInfomation[index].name =  this.componentsInfo[i].parts;
        //        this.list.componentsInfomation[index].number =  this.componentsInfo[i].number;
        //       //  this.list.componentsInfomation[index].badNr =  this.componentsInfo[i].badNr;
        //       //  this.list.componentsInfomation[index].newNr =  this.componentsInfo[i].newNr;
        //       //  this.list.componentsInfomation[index].price =  this.componentsInfo[i].price;
        //      }
        //    }
        //  }
      }
    },
    creatNew()
    {
      if (this.list.componentsInfomation.length < 10) {
        this.list.componentsInfomation.push({
          name: null,
          number: null,
          badNr: null,
          newNr: null,
          price: null,
          count: 1
        });
      }
    },
    async getCustomerInfo()
    {
      const { data } = await getCustomerList();

      this.customerInfo = data.list;
      //console.log(this.customerInfo);

      this.listLoading = false;
    },
    async getComponentsInfo()
    {
      const { data } = await getComponentsList();
     // console.log(data);
      this.componentsInfo = data.list;
      this.listLoading = false;
    },
    async creatForm()
    {
      delete this.list["_id"];
      const { data } = await createArticle(this.list);
      if (data) {
        this.id = data;
        Message({
          message: "创建成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
    },
    async deleteForm()
    {
      this.showDelete = false;
      if (this.id) {
        const data = await deleteArticle({ id: this.id });
        if (data) {
          Message({
            message: "删除成功",
            type: "success",
            duration: 5 * 1000,
          });
        }
        this.newForm();
      } else {
        Message({
          message: "删除失败",
          type: "faile",
          duration: 5 * 1000,
        });
      }
    },
    async updateForm()
    {
      if (this.list.Date === "") { this.list.Date = null; };
      if (this.list.productInfo.return === "") { this.list.productInfo.return = null; };
      if (this.list.productInfo.delivery === "") { this.list.productInfo.delivery = null; };
      if (this.list.productInfo.warranty === "") { this.list.productInfo.warranty = null; };
     // console.log(this.list);
      const updateData = {
        id: this.id,
        data: this.list,
      };
      const data = await updateArticle(updateData);
      if (data) {
        this.id = data.data;
        Message({
          message: "保存成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
    },
    resizeHeight(e)
    {
      var elem = e.currentTarget.children[0];
      elem.style.height = "0";
      elem.scrollTop = "0";
      const { scrollHeight } = elem;
      if (scrollHeight > elem.offsetHeight) {
        elem.style.height = `${scrollHeight}px`;
      }
    },
    newForm()
    {
      this.id = null;
      this.list = {
        customerInfo: {
          company: null,
          contactor: null,
          tel: null,
          fax: null,
          add: null,
          warranty: null,
        },
        productInfo: {
          SN: null,
          module: null,
          articl: null,
          hardware: null,
          software: null,
          report: null,
          parts: null,
          po: null,
          return: null,
          delivery: null,
          warranty: null,

          Quote: null,
          reportNumber: null,
        },
        failureDescription: null,
        analysis: null,
        componentsInfomation: [
          {
            name: null,
            count: 1,
            number: null,
            badNr: null,
            newNr: null,
            price: null,
          },
        ],
        SuppliercomponentsInfomation: [
          {
            name: null,
            count: null,
            number: null,
            badNr: null,
            newNr: null,
            price: null,
          },
        ],
        back: false,
        SupplierRelated: {
          Sigmatek_MessageToSupplier: null,
          Supplier_Complete: null,
          Supplier_TotalPrice: null,
          Supplier_TrackingNumber: null,
          Supplier_DeliveryDate: null,
        },
        times: null,
        contactor: "Kyle",
        Date: null,
      };
    },
    remove(index)
    {
      this.list.componentsInfomation.splice(index, 1);
    },
    DeliveryChange(value)
    {
      var WarrantyDays;
      this.customerInfo.forEach((company) =>
      {
        if (company.company == this.list.customerInfo.company) {
          if (company.WarrantyDays != null) {
            WarrantyDays = company.WarrantyDays;
          }
          return;
        }
      });
      // var valueDate = new Date(value);
      // var WarrantyDate = new Date(valueDate.getTime()+WarrantyDays * 24 * 60 * 60 * 1000).toLocaleString().split(" ")[0].replace("/","-").replace("/","-");
      this.list.productInfo.warranty = this.GetPreMonthDay(value, WarrantyDays);
    },
    GetPreMonthDay(date, monthNum)
    {
      var dateArr = date.split("-");
      var year = dateArr[0]; //获取当前日期的年份
      var month = dateArr[1]; //获取当前日期的月份
      var day = dateArr[2]; //获取当前日期的日
      var year2 = year;
      var day2 = day;
      var month2 = parseInt(month) + parseInt(monthNum);
      if (month2 > 12) {
        year2 =
          parseInt(year2) +
          parseInt(month2 / 12 == 0 ? 1 : parseInt(month2) / 12);
        month2 = Math.abs(month2) % 12;
        if (month2 == 0) {
          month2 = 12;
        }
      }
      if (month2 == 4 || 6 || 9 || 11) {
        if (day == 31) {
          day2 = 30;
        }
      }
      if (month2 == 2) {
        if (day >= 28) {
          day2 = 28;
        }
      }
      if (month2 < 10) {
        month2 = "0" + month2;
      }
      var t2 = year2 + "-" + month2 + "-" + day2;
      return t2;
    },
    cancelEdit(row)
    {
      // row.title = row.originalTitle
      // row.edit = false
      // this.$message({
      //   message: 'The title has been restored to the original value',
      //   type: 'warning'
      // })
    },
    confirmEdit(row)
    {
      // row.edit = false
      // row.originalTitle = row.title
      // this.$message({
      //   message: 'The title has been edited',
      //   type: 'success'
      // })
    },
    formatDate(timestamp)
    {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    beforePrint()
    {
      if (this.list.back) {
        this.Switch_Panel = true;
        this.AddRow();
        this.SupplierPrintHidden = false;
      }
      this.PrintHidden = false;
      // this.$refs.Quote.style.visibility = "hidden";
      // this.$refs.reportNumber.style.visibility = "hidden";
      // this.$refs.fault.$el.style.visibility = "hidden";
      // this.$refs.fault1.$el.style.visibility = "hidden";
      // this.$refs.secondfault.$el.style.visibility = "hidden";
      // this.ClearRow();
    },
    AddRow()
    {
      if (this.list.componentsInfomation.length < 2) {
        do {
          this.list.componentsInfomation.push(
            // Object.assign({})
            {
              name: null,
              number: null,
              badNr: null,
              newNr: null,
              price: null,
            }
          );
        } while (this.list.componentsInfomation.length < 5);
      } else {
        for (let i = 0; i < 3; i++) {
          this.list.componentsInfomation.push(
            // Object.assign({})
            {
              name: null,
              number: null,
              badNr: null,
              newNr: null,
              price: null,
              count: 1,
            }
          );
        }
      }
      // console.log(this.list.componentsInfomation);
    },

    ClearRow()
    {
      let hasEmptyRow = false; // 是否已经遇到空行
      this.list.componentsInfomation = this.list.componentsInfomation.filter(
        (item) =>
        {
          const isEmpty =
            !item.name &&
            !item.number &&
            !item.badNr &&
            !item.newNr;
          if (isEmpty) {
            if (hasEmptyRow) {
              return false; // 有多余的空行，剔除
            } else {
              hasEmptyRow = true; // 遇到第一个空行，保留
              return true;
            }
          }
          return true; // 有有效值的行，保留
        }
      );
    },
  },
};
</script>

<style scoped>
.edit-input>>>.el-input__inner {
  padding-right: 100px;
  border: 0px;
  height: 3.5mm;
  padding: 0;
  width: 98%;
  color: #000;
  font-size: 2.5mm;
}

.edit-input>>>.el-textarea__inner {
  padding-right: 100px;
  overflow: hidden !important;
  border: 0px;
  resize: none;
  padding: 0;
  width: 98%;
  min-height: 16mm;
  font-size: 2.5mm;
  color: #000;
}

.left_space {
  margin-left: 4mm;
}

.edit-input1>>>.el-textarea__inner {
  min-height: 17mm !important;
  /* height: 15mm; */
  overflow: hidden;
}

.noPadding {
  padding: 0;
  width: 100%;
}

.delete:hover {
  background-image: url("../../../Img/delete.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

.create:hover {
  background-image: url("../../../Img/create.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

.delete:hover button {
  color: transparent;
}

.date>>>.el-input__inner::-webkit-inner-spin-button {
  display: none;
}

.date>>>.el-input__inner::-webkit-clear-button {
  display: none;
}

.date>>>.el-input__inner::-webkit-calendar-picker-indicator {
  width: 2mm;
  padding: 0px;
  margin: 0;
}

h2 {
  margin-top: 2mm;
  font-size: 6mm;
}

.transparent:visited {
  border: 0;
}

.transparent:focus {
  border: 0;
}

.transparent:active {
  border: 0;
}

.log {
  position: relative;
  width: 40mm;
  height: 4mm;
  left: 125mm;
  top: 10mm;
}

.bottomArea {
  position: absolute;
  bottom: 2mm;
}

.noBorder {
  border: 0 !important;
}

.text_right {
  text-align: right;
}

.sizesmall {
  height: 4mm;
}

.check-input {
  background-color: black;
  position: relative;
  top: 1mm;
  left: 1mm;
}

.title {
  width: 20mm;
  display: inline-block;
}

.title-right {
  width: 30mm;
  display: inline-block;
}

.relative {
  position: relative;
}

.edit-input2>>>.el-textarea__inner {
  border: 0;
  min-height: 20mm !important;
  overflow: hidden;
  height: auto;
  color: #000;
}

.edit-input3>>>.el-textarea__inner {
  border: 0;
  min-height: 6mm !important;
  margin-top: 3mm;
  overflow: hidden;
  height: auto;
  color: #000;
}

.bottom-input>>>.el-input__inner {
  padding-right: 100px;
  border: 0;
  height: 4mm;
  padding: 0;
  margin: 0px;
  font-size: 2.5mm;
  color: #000;
}

.app-container {
  height: 500mm;
}

.inlineblock {
  display: inline-block;
}

.timesInput {
  display: inline-block;
  width: 4mm;

  border-bottom: 1px solid #000;
}

.area_right {
  position: absolute;
  right: 6mm;
  top: 0px;
}

.writeInput {
  display: inline-block;
  width: 20mm;
  height: 5.5mm;
  border-bottom: 0.5px solid #000;
}

.bottomInfo {
  margin-top: 2.2mm;
  font-size: 2.5mm;
  margin-bottom: 0.1mm;
}

table {
  border-right: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  width: 166mm;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

td {
  word-break: break-all;
  font-size: 2.3mm;
}

.clomun1 {
  width: 28mm;
}

.clomun11 {
  width: 5mm;
}

.clomun12 {
  width: 35mm;
}

.clomun15 {
  width: 40mm;
}

.clomun16 {
  width: 14mm;
}

.clomun2 {
  width: 20mm;
}

.clomun3 {
  width: 28mm;
}

.clomun4 {
  width: 40mm;
}

.clomun5 {
  width: 32mm;
}

.clomun6 {
  width: 23mm;
}

.Failure {
  vertical-align: top;
  min-height: 16mm;
}

.Analysis {
  vertical-align: top;
  /* min-height: 24mm; */
}

.inline {
  display: inline-block;
  width: 7px;
}

.grayfont {
  color: gray;
}

.Notice {
  height: 12mm;
  vertical-align: top;
}

.Notice p {
  margin: 1px;
}

.Notice td {
  padding: 0px;
}

#printTest {
  position: relative;
  top: 100mm;
  left: 75mm;
  padding: 0.5mm;
  margin-right: 400px;
  margin-bottom: 0px;
  width: 173mm;
  height: 242mm;
  transform: scale(1.8);
}

.italic {
  font-style: italic;
  font-size: 5mm;
}

tr {
  height: 4mm;
}

.filter>>>.el-dialog {
  width: 80%;
}

td {
  height: 4mm;
  border-left: 0.5px solid #000;
  border-top: 0.5px solid #000;
}

.transparent {
  background-color: transparent;
}

h6 {
  font-weight: bold;
  margin-top: 2.5mm;
  margin-bottom: 0.6mm;
}

.text-right {
  font-family: sans-serif;
  font-size: 2.5mm;
  text-align: right;
  padding-right: 0.6mm;
  color: #000;
}

.input-right>>>.el-input__inner {
  text-align: end;
  color: #000;
}

.Reportinput {
  width: 70%;
  border: none;
  outline: medium;
  display: inline-block;
  background-color: white;
}

.Quoteinput {
  width: 40%;
  border: none;
  outline: medium;
  display: inline-block;
}

.Quoteinput_Supplier {
  width: 100%;
  border: none;
  padding: 0;
  color: #000;
  font-size: 2.5mm;
  text-align: right;
}

.clomun33 {
  width: 50mm;
}

.faultclass>>>.el-input__inner {
  font-size: 12px;
  /* margin-left: 10px; */
}

.faultclass {
  margin-left: 10px;
  /* margin-right: 10px; */
}

.divider {
  margin: 0px;
}

.Revertstyle {
  display: inline-block;
  margin-top: 3px;
  margin-bottom: 3px;
}

.clomun17 {
  width: 15mm;
}

.Revertinput {}

.InfoPrompt {
  margin: 0px;
  font-size: 10px;
  padding-right: 5px;
  opacity: 0.2;
  font-style: italic;
}
</style>

<style scoped lang="scss">
::v-deep .el-autocomplete-suggestion {
  width: 130px !important;
}

::v-deep .el-autocomplete-suggestion__list li {
  line-height: 25px !important;
  font-size: 10px !important;
  padding: 0 10px !important;
}
</style>